<template>
  <div>
    <div class="input-group">
      <input ref="passwordRef" v-model="data.password" @keydown.space.prevent @keyup.enter="submit()" type="password" class="form-control" required :placeholder="$t('components.auth.steps.password.placeholder')"/>
    </div>

    <p class="text-danger mb-0 mt-1" v-if="!$v.data.password.required && computedHasToValidateRequired">{{ $t('components.auth.steps.password.validations.required') }}</p>
    <p class="text-danger mb-0 mt-1" v-else-if="!$v.data.password.minLength && computedHasToValidateRequired">{{ $t('components.auth.steps.password.validations.required', { minLength: $v.data.password.$params.minLength.min }) }}</p>

    <button @click="submit()" :disabled="computedIsLoading" class="btn w-100 btn-primary mt-4">
      <template v-if="computedIsLoading">
         <i class="fe fe-loader"></i> {{ $t('components.auth.steps.password.loading') }}
      </template>
      <template v-else>
        {{ $t('components.auth.steps.password.continue') }}
      </template>
    </button>
  </div>
</template>
  
<script>
import { required, minLength } from 'vuelidate/lib/validators'

export default {
  name: 'PasswordStep',
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      data: {
        password: null,
        passwordInputType: 'password',
      },
      interaction: {
        hasToValidateRequired: false,
      }
    };
  },
  validations: {
    data: {
      password: {
        required,
        minLength: minLength(6),
      },
    },
  },
  computed: {
    computedHasTypedPassword() {
      return (this.data.password && this.data.password.length > 0) || false;
    },
    computedHasToValidateRequired() {
      return this.interaction.hasToValidateRequired || false;
    },
    computedIsLoading() {
      return this.isLoading || false;
    },
  },
  methods: {
    async submit() {
      this.interaction.hasToValidateRequired = true;

      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.$emit('submit', this.data.password)
      }   
    },
    togglePasswordInputType() {
      if (this.formInteraction.passwordInputType == 'password') {
        this.formInteraction.passwordInputType = 'text';
      } else {
        this.formInteraction.passwordInputType = 'password';
      }
    },
  },
  mounted() {
    this.$refs.passwordRef.focus();
  },
  watch: {
    'data.password': function() {
      this.interaction.hasToValidateRequired = false;
    },
  },
};
</script>