<template>
  <div id="app">
    <link rel="stylesheet" href="/css/theme.css"/>
    <link rel="stylesheet" href="/css/custom.css"/>
    <link rel="stylesheet" href="/css/phone.css"/>
    <router-view></router-view>
  </div>
</template>

<script>
  export default {
    name: 'adminer-register-front',
    components: {
    },
    computed: {
      messages () {
        return this.$store.getters.messages;
      },
    },
    created() {
    },
    updated() {

    },
    watch: {
      messages(val) {
        if (val.text.length > 0) {
          this.$toast(val.text, {
            timeout: 2000,
            type: val.kind
          });
        }
      },
    },
  }
</script>