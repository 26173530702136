// import i18n from '../../plugins/i18n';

const actions = {
  handleError({ dispatch }, request) {
    try {
      const { status } = request.response;
      const { errors } = request.response.data;
      switch (status) {
        case 401:
          dispatch('logout');
          // dispatch('addErrorMessage', 'Não autorizado');
          break;
        case 404:
          dispatch('addErrorMessage', 'Não encontrado');
          break;
        case 422:
          if (errors) {
            let error;
            if (typeof errors === 'string') {
              error = errors;
            } else {
              [error] = Object.values(errors);
            }
            dispatch('addErrorMessage', error);
          } else {
            dispatch('addErrorMessage', 'Ocorreu um erro');
          }
          break;
        case 403:
          if (errors) {
            dispatch('addErrorMessage', errors);
          } else {
            dispatch('addErrorMessage', 'Forbidden');
          }
          break;
        default:
          dispatch('addErrorMessage', 'Ocorreu um erro');
          dispatch('removeLoading');
          break;
      }
      dispatch('removeLoading');
    } catch (e) {
      dispatch('addErrorMessage', 'Ocorreu um erro');
      dispatch('removeLoading');
    }
  },
};

export default {
  actions,
};
