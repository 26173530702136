<template>
  <div style="padding: 40px; border-radius: 12px">
    <div class="text-color-light text-align-center">
      <h1 data-w-id="e9205504-915d-47e5-2a91-f37cc5c1d087" style="opacity: 1;" class="heading-style-h1 display-inline"></h1>
      <img src="https://uploads-ssl.webflow.com/63a1b54c23a6b7879791f4a4/63a4da98c8e058a4e16f54d2_Logo%20motion.gif" loading="lazy" style="transform-style: 
  preserve-3d; 
  opacity: 1; transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);" data-w-id="91f61027-6ae5-c355-8797-272c10b54e07" alt="" class="delay_image-logo">
    </div>
  </div>
</template>

<script>
export default {
  name: 'GoogleAccountLoading',
  data() {
    return {
      interaction: {
        isUserLoading: true,
      }
    };
  },
  computed: {
    computedIsUserLoading() {
      return this.interaction.isUserLoading || false;
    },
  }
};
</script>

<style lang="scss" scoped>
body {
  background-color: #0A0A0A;
  background-image: none !important;
}

* {
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
}

h1 {
  margin: 0;
}

img {
  border-style: initial;
  border-width: 0;
  display: inline-block;
  max-width: 100%;
  vertical-align: middle;
}

.inherited-styles-for-exported-element {
  color: #333;
  font-family: Roboto, sans-serif;
  font-size: 1rem;
  line-height: 1.5;
}

.text-weight-bold {
  font-weight: 700;
}

.text-align-center {
  text-align: center;
}

.padding-global {
  display: block;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.container-medium {
  max-width: 73.75rem;
  width: 100%;
}

.text-size-large {
  font-size: 20px;
}

.heading-style-h2 {
  font-size: 52px;
  font-weight: 500;
}

.display-inline {
  display: inline;
}

.align-center {
  margin-left: auto;
  margin-right: auto;
}

.text-color-light {
  color: #fff;
}

.text-color-light-3 {
  color: #b5b3b3;
}

.delay_component,
.section_delay {
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-box-pack: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.section_delay {
  -webkit-box-align: stretch;
  align-items: stretch;
  min-height: 100vh;
  padding-top: 0;
  width: 100%;
}

.delay_component {
  -webkit-box-align: center;
  align-items: center;
  row-gap: 1.5rem;
}

.delay_image-logo {
  margin-left: 0;
  margin-top: -.8rem;
  width: 3.8rem;
}

.heading_wrap {
  -webkit-box-align: center;
  align-items: center;
}

@media screen and (min-width: 1280px) {
  .section_delay {
    padding-top: 0;
  }

  .delay_image-logo {
    margin-top: -.8rem;
  }
}

@media screen and (min-width: 1920px) {
  .section_delay {
    padding-top: 0;
  }

  .delay_image-logo {
    width: 4.1rem;
  }
}

@media screen and (max-width: 991px) {
  .heading-style-h2 {
    font-size: 2.5rem;
  }

  .section_delay {
    padding-top: 0;
  }

  .delay_image-logo {
    margin-top: -.8rem;
  }
}

@media screen and (max-width: 767px) {
  .padding-global {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .text-size-large {
    font-size: 1.063rem;
  }

  .delay_component {
    -webkit-box-align: center;
    -webkit-box-pack: center;
    align-items: center;
    display: flex;
    justify-content: center;
    row-gap: 1.5rem;
  }

  .delay_image-logo {
    margin-left: 0;
    margin-top: -.5rem;
    width: 3.2rem;
  }
}

@media screen and (max-width: 479px) {
  .section_delay {
    padding-top: 0;
  }

  .delay_image-logo {
    margin-left: 0;
    margin-top: -.4rem;
    width: 2.6rem;
  }
}

.heading_wrap.text-color-light {
  display: block;
}

.description-wrap.align-center {
  max-width: 30rem;
  text-align: center;
  width: 49%;
}

@media screen and (min-width: 1280px) {
  .description-wrap.align-center {
    width: 50%;
  }
}

@media screen and (min-width: 1920px) {
  .heading-style-h2.display-inline {
    font-size: 3.2rem;
  }

  .text-color-light-3.text-size-large {
    font-size: 1.2rem;
  }

  .description-wrap.align-center {
    max-width: 30rem;
    width: 41%;
  }
}

@media screen and (max-width: 991px) {
  .text-color-light-3.text-size-large {
    font-size: 1rem;
  }

  .description-wrap.align-center {
    width: 61%;
  }
}

@media screen and (max-width: 767px) {
  .heading-style-h2.display-inline {
    font-size: 2rem;
  }

  .description-wrap.align-center {
    width: 74%;
  }
}

@media screen and (max-width: 479px) {
  .heading-style-h2.display-inline {
    width: 100%;
  }

  .heading_wrap.text-color-light {
    display: block;
  }

  .description-wrap.align-center {
    max-width: 28.125rem;
    width: 94%;
  }
}

.heading_wrap.text-color-light.text-align-center {
  max-width: 40.5rem;
  width: 66%;
}

@media screen and (min-width: 1920px) {
  .heading_wrap.text-color-light.text-align-center {
    max-width: 41.5rem;
    width: 74%;
  }
}

@media screen and (max-width: 991px) {
  .heading_wrap.text-color-light.text-align-center {
    width: 79%;
  }
}

@media screen and (max-width: 479px) {
  .heading_wrap.text-color-light.text-align-center {
    width: 100%;
  }
}

.container-medium {
  margin-left: auto !important;
  margin-right: auto !important;
}

html {
  font-size: 20px;
  /* This is IMPORTANT since some copied values use "REM" units */
}
</style>