<template>
  <modal classes="modal-mobile" name="modal-mobile">
    <div class="container-mobile">
      <img align="right" @click="hideModalMobile" src="/img/close-sign.svg" style="cursor: pointer;">
      <img class="pb-2" src="/img/emoji-surprise.svg" style="display: block; margin-left: auto; margin-right: auto;">
      <div class="modal-sub-header-mobile pb-2 w-100">{{ $t('components.plans.modals.monthlyMobile.title') }}</div>
      <div class="modal-text-mobile pb-2 pt-2">{{ $t(`components.plans.modals.monthlyMobile.description.${occurrence}`) }}</div>
      <div class="modal-footer-custom" style="padding-top: 2rem !important;">
        <button @click="successMobile" class="button-primary is-light is-small w-80 pt-2"
          style="font-size: 16px;padding-bottom: 0.75rem !important;padding-top: 0.75rem !important; border:unset;">{{ $t(`components.plans.modals.monthlyMobile.cta.${occurrence}`) }}</button>
      </div>
    </div>
  </modal>
</template>

<script>
export default {
  name: 'MonthlyMobile',
  props: {
    occurrence: {
      type: String,
      required: true
    }
  },
  methods: {
    showModalMobile() {
      this.$modal.show('modal-mobile');
    },
    hideModalMobile() {
      this.$modal.hide('modal-mobile');
    },
    successMobile() {
      this.$emit('success');
    }
  }
}
</script>

<style>
.modal-mobile {
  width: 340px !important;
  height: 398px !important;
  background-color: #101010 !important;
  border-radius: 8px !important;
  border: 1px solid #2D2D2D;
  margin-left: auto !important;
  margin-right: auto !important;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
}

.vm--overlay {
    background: rgba(0,0,0,.8) !important;
    opacity: 1 !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-sub-header-mobile {
  justify-content: center !important;
  text-align: center !important;
  color: #ffffff;
  width: 300px;
  margin: auto;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 100%;
}

.modal-text-mobile {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 150%;
  display: flex;
  margin: auto !important;
  align-items: center !important;
  text-align: center;
  color: #B5B3B3;
  padding-top: 5px;
  width: auto !important;
}

.container-mobile {
  display: block;
  margin-top: 45px;
  margin-left: 20px;
  margin-right: 20px;
}

.text-span-4{
  color: #ffffff;
}

@media screen and (max-width: 600px){
  .modal-text.mobile{
    padding-bottom: 1rem !important;
  }
}
</style>