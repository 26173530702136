<template>
  <modal classes="otherplans-modal-size" name="otherplans-desktop">
    <div style="height: 1.2rem;">
      <div v-show="monthlyContent && this.computedHasAffiliate && this.computedHasDiscount" class="off_wrap bg-white upper-tag-affiliate" style="display: flex;">
        <div class="text-align-center monthly-text">
          {{ $t('components.plans.modals.otherPlansDesktop.card.header.discount.affiliate', { discount: computedDiscountText, affiliateName: computedHasAffiliate }) }}
        </div>
      </div>
      <div v-show="quarterlyContent" class="upper-tag">
        <img src="/img/cupom-icon.svg">
        {{ $t('components.plans.modals.otherPlansDesktop.card.header.discount.quarterly') }}
      </div>
    </div>
    <div class="otherplans-modal-body pt-10">
      <img align="right" @click="hideModalDesktop" src="/img/close-sign.svg" style="cursor: pointer; margin-right: 1.15rem;">
      <div class="otherplans-header mt-3 d-flex">
        <div class="toggle-wrapper" style="width:30%; margin-left: .15rem;"> 
          <div class="toggle-bg table">
            <div @click="changePriceFromMonthly(), showQuarterlyContent(), selectedPlan = 'quarterly'" class="toggle-text monthly" id="quarterly-button">
            <div v-if="computedSelectedPlan == 'quarterly'" class="toggle-btn table" style="width: 114px !important;"></div>
              {{ $t('components.plans.modals.otherPlansDesktop.card.header.occurrence.quarterly') }}
            </div>
            <div @click="changePriceFromQuarterly(), hideQuarterlyContent(), selectedPlan = 'monthly'" class="toggle-text monthly">
            <div v-if="computedSelectedPlan == 'monthly'" class="toggle-btn table"></div>
              {{ $t('components.plans.modals.otherPlansDesktop.card.header.occurrence.monthly') }}
            </div>
          </div>
        </div>
        <div class="d-flex plan-container" style="width: 70%;">
          <div class="gold-container">
            <div class="d-flex">
              <h2 class="plan-container-title">Gold</h2>
              <div class="bg-white text-center" v-show="this.computedHasTrial && selectedPlan == 'monthly'" style="padding: 2px 8px;border-radius:2px;transform: rotate(-1deg);width: 52% !important;margin-left: 10%;top: -10px">
                <p class="m-0" style="font-size:14px;background: linear-gradient(180deg, #F29932 0%, #E75341 100%);-webkit-background-clip: text;-webkit-text-fill-color: transparent;background-clip: text;text-fill-color: transparent;">
                  {{ $t('components.plans.modals.otherPlansDesktop.card.header.cta.trial',  { trialDays: computedTrial }) }}
                </p>
              </div>
            </div>
            <div v-show="quarterlyContent || this.computedHasDiscount" class="bad-price d-flex">
              <p v-show="quarterlyContent || this.computedHasDiscount" style="align-self: center; font-size: .7rem; margin-bottom: 0rem;">{{ computedFormattedCurrency }}</p> 
              <p v-show="quarterlyContent || this.computedHasDiscount" style="padding-left: .2rem; margin-bottom: 0rem;">{{ parseInt(user.gold_config.monthly.amount) }}</p>
            </div>
            <div v-show="quarterlyContent || this.computedHasDiscount" class="cross-line"></div>
            <div class="good-price d-flex">
              <p style="align-self: center; font-size: .7rem; margin-bottom: 0rem;">{{ computedFormattedCurrency }}</p> 
              <p v-show="selectedPlan == 'monthly' && this.computedHasAffiliate && this.computedHasDiscount && user.gold_config.monthly.coupon?.percent_off" style="padding-left: .2rem; margin-bottom: 0rem; font-size: 2rem;">{{ parseInt(user.gold_config.monthly.amount - (user.gold_config.monthly.amount * (user.gold_config.monthly.coupon?.percent_off / 100))) }}</p>
              <p v-show="selectedPlan == 'monthly' && this.computedHasAffiliate && this.computedHasDiscount && user.gold_config.monthly.coupon?.amount_off" style="padding-left: .2rem; margin-bottom: 0rem; font-size: 2rem;">{{ parseInt(user.gold_config.monthly.amount - (user.gold_config.monthly.coupon?.amount_off)) }}</p>
              <p v-show="selectedPlan == 'monthly' && this.computedHasDiscount == null" style="padding-left: .2rem; margin-bottom: 0rem; font-size: 2rem;">{{ bigPriceGold }}</p>
              <p v-show="selectedPlan == 'quarterly'" style="padding-left: .2rem; margin-bottom: 0rem; font-size: 2rem;">{{ bigPriceGold }}</p>
              <div style="align-self: center;">
                <p v-show="quarterlyContent" class="price-text">{{ $t('components.plans.modals.otherPlansDesktop.card.header.price.perMonth') }}</p> 
                <p v-show="quarterlyContent" class="price-text">{{ $t('components.plans.modals.otherPlansDesktop.card.header.price.quarterlyCharged') }}</p>
              </div>
            </div>
          </div>  
          <div class="diamond-container">  
            <h2 class="plan-container-title">Diamond</h2>
            <div v-show="quarterlyContent" class="bad-price d-flex">
              <p v-show="quarterlyContent" style="align-self: center; font-size: .7rem; margin-bottom: 0rem;">{{ computedFormattedCurrency }}</p> 
              <p v-show="quarterlyContent" style="padding-left: .2rem; margin-bottom: 0rem;">{{ parseInt(user.diamond_config.monthly.amount) }}</p>
            </div> 
            <div v-show="quarterlyContent" class="cross-line">
            </div>  
            <div class="good-price d-flex">
              <p style="align-self: center; font-size: .7rem; margin-bottom: 0rem;">{{ computedFormattedCurrency }}</p> 
              <p :v-html="bigPriceDiamond" style="padding-left: .2rem; margin-bottom: 0rem; font-size: 2rem;">{{ bigPriceDiamond }}</p>
              <div style="align-self: center;">
                <p v-show="quarterlyContent" class="price-text">{{ $t('components.plans.modals.otherPlansDesktop.card.header.price.perMonth') }}</p> 
                <p v-show="quarterlyContent" class="price-text">{{ $t('components.plans.modals.otherPlansDesktop.card.header.price.quarterlyCharged') }}</p> 
              </div>
            </div>
          </div>  
        </div>
      </div>
      <div class="plans-benefits">
          <div class="modal-content-desktop">
          <div class="pricing-table-category">
            <div class="pricing-table-row" style="border-bottom: unset; padding-top: .3rem; align-items: self-end;"> 
              <div class="pricing-table-row-title" style="width: 32% !important; padding-left: 30px;">
                <div class="pricing-card-row mobile-center" style="border-bottom: 2px solid #3a3a3a;">
                  <div class="pricing-row-text title otherplans-text">{{ $t('components.plans.modals.otherPlansDesktop.card.body.benefits.mining.title') }}</div>
                </div>
              </div>
              <div class="pricing-table-row-cols w-row">
                <div class="w-col w-col-3 w-col-small-3 w-col-tiny-3">
                  <div class="pricing-table-content" style="border-bottom: 2px solid #3a3a3a;" >
                    <div class="pricing-card-row center">
                      <div class="pricing-icon table" style="margin-bottom: 1rem;"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="pricing-table-row-cols w-row">
                <div class="w-col w-col-3 w-col-small-3 w-col-tiny-3">
                  <div class="pricing-table-content" style="border-bottom: 2px solid #3a3a3a;">
                    <div class="pricing-card-row center">
                      <div class="pricing-icon table" style="margin-bottom: 1rem;"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="pricing-table-category">
            <div class="pricing-table-row" style="border-bottom: unset; padding-top: .3rem; align-items: self-end;">
              <div class="pricing-table-row-title" style="width: 32% !important; padding-left: 30px;">
                <div class="pricing-card-row mobile-center" style="border-bottom: 2px solid #3a3a3a;">
                  <div class="pricing-row-text title otherplans-text" >{{ $t('components.plans.modals.otherPlansDesktop.card.body.benefits.premiumLib.title') }}</div>
                </div>
              </div>
              <div class="pricing-table-row-cols w-row">
                <div class="w-col w-col-3 w-col-small-3 w-col-tiny-3">
                  <div class="pricing-table-content" style="border-bottom: 2px solid #3a3a3a;">
                    <div class="pricing-card-row center">
                      <div class="pricing-icon" style="margin-bottom: 1rem; font-size: .9rem; color: #fff;"> {{ $t('components.plans.modals.otherPlansDesktop.card.body.benefits.premiumLib.value', { franchise: 20 }) }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="pricing-table-row-cols w-row">
                <div class="w-col w-col-3 w-col-small-3 w-col-tiny-3">
                  <div class="pricing-table-content" style="border-bottom: 2px solid #3a3a3a;">
                    <div class="pricing-card-row center">
                      <div class="pricing-icon" style="margin-bottom: 1rem; font-size: .9rem; color: #fff;"> {{ $t('components.plans.modals.otherPlansDesktop.card.body.benefits.premiumLib.value', { franchise: 60 }) }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="pricing-table-category">
            <div class="pricing-table-row" style="border-bottom: unset; padding-top: .3rem; align-items: self-end;">
              <div class="pricing-table-row-title" style="width: 32% !important; padding-left: 30px;">
                <div class="pricing-card-row mobile-center" style="border-bottom: 2px solid #3a3a3a;">
                  <div class="pricing-row-text title otherplans-text">{{ $t('components.plans.modals.otherPlansDesktop.card.body.benefits.advancedFilters.title') }}</div>
                </div>
              </div>
              <div class="pricing-table-row-cols w-row">
                <div class="w-col w-col-3 w-col-small-3 w-col-tiny-3">
                  <div class="pricing-table-content" style="border-bottom: 2px solid #3a3a3a;">
                    <div class="pricing-card-row center">
                      <div class="pricing-icon table"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="pricing-table-row-cols w-row">
                <div class="w-col w-col-3 w-col-small-3 w-col-tiny-3">
                  <div class="pricing-table-content" style="border-bottom: 2px solid #3a3a3a;">
                    <div class="pricing-card-row center">
                      <div class="pricing-icon table"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="pricing-table-category">
            <div class="pricing-table-row" style="border-bottom: unset; padding-top: .3rem; align-items: self-end;">
              <div class="pricing-table-row-title" style="width: 32% !important; padding-left: 30px;">
                <div class="pricing-card-row mobile-center" style="border-bottom: 2px solid #3a3a3a;">
                  <div class="pricing-row-text title otherplans-text">{{ $t('components.plans.modals.otherPlansDesktop.card.body.benefits.marketAnalysis.title') }}</div>
                </div>
              </div>
              <div class="pricing-table-row-cols w-row">
                <div class="w-col w-col-3 w-col-small-3 w-col-tiny-3">
                  <div class="pricing-table-content" style="border-bottom: 2px solid #3a3a3a;">
                    <div class="pricing-card-row center">
                      <div class="pricing-icon table" style="margin-bottom: 1rem;"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="pricing-table-row-cols w-row">
                <div class="w-col w-col-3 w-col-small-3 w-col-tiny-3">
                  <div class="pricing-table-content" style="border-bottom: 2px solid #3a3a3a;">
                    <div class="pricing-card-row center">
                      <div class="pricing-icon table" style="margin-bottom: 1rem;"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="pricing-table-category">
            <div class="pricing-table-row" style="border-bottom: unset; padding-top: .3rem; align-items: self-end;">
              <div class="pricing-table-row-title" style="width: 32% !important; padding-left: 30px;">
                <div class="pricing-card-row mobile-center" style="border-bottom: 2px solid #3a3a3a;">
                  <div class="pricing-row-text title otherplans-text">{{ $t('components.plans.modals.otherPlansDesktop.card.body.benefits.hotProducts.title') }}</div>
                </div>
              </div>
              <div class="pricing-table-row-cols w-row">
                <div class="w-col w-col-3 w-col-small-3 w-col-tiny-3">
                  <div class="pricing-table-content" style="border-bottom: 2px solid #3a3a3a;">
                    <div class="pricing-card-row center">
                      <div class="pricing-icon" style="font-size: .9rem; color: #fff;"> {{ $t('components.plans.modals.otherPlansDesktop.card.body.benefits.hotProducts.goldValue') }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="pricing-table-row-cols w-row">
                <div class="w-col w-col-3 w-col-small-3 w-col-tiny-3">
                  <div class="pricing-table-content" style="border-bottom: 2px solid #3a3a3a;">
                    <div class="pricing-card-row center">
                      <div class="pricing-icon" style="font-size: .9rem; color: #fff;"> {{ $t('components.plans.modals.otherPlansDesktop.card.body.benefits.hotProducts.diamondValue') }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="pricing-table-category">
            <div class="pricing-table-row" style="border-bottom: unset; padding-top: .3rem; align-items: self-end;">
              <div class="pricing-table-row-title" style="width: 32% !important; padding-left: 30px;">
                <div class="pricing-card-row mobile-center" style="border-bottom: 2px solid #3a3a3a;">
                  <div class="pricing-row-text title otherplans-text">{{ $t('components.plans.modals.otherPlansDesktop.card.body.benefits.copy.title') }}</div>
                </div>
              </div>
              <div class="pricing-table-row-cols w-row">
                <div class="w-col w-col-3 w-col-small-3 w-col-tiny-3">
                  <div class="pricing-table-content" style="border-bottom: 2px solid #3a3a3a;">
                    <div class="pricing-card-row center">
                      <div class="pricing-icon table not-included" style="margin-bottom: 1rem;"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="pricing-table-row-cols w-row">
                <div class="w-col w-col-3 w-col-small-3 w-col-tiny-3">
                  <div class="pricing-table-content" style="border-bottom: 2px solid #3a3a3a;">
                    <div class="pricing-card-row center">
                      <div class="pricing-icon table" style="margin-bottom: 1rem;"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="pricing-table-category">
            <div class="pricing-table-row" style="border-bottom: unset; padding-top: .3rem; align-items: self-end;">
              <div class="pricing-table-row-title" style="width: 32% !important; padding-left: 30px;">
                <div class="pricing-card-row mobile-center" style="border-bottom: 2px solid #3a3a3a;" >
                  <div class="pricing-row-text title otherplans-text">{{ $t('components.plans.modals.otherPlansDesktop.card.body.benefits.tiktok.title') }}</div>
                </div>
              </div>
              <div class="pricing-table-row-cols w-row">
                <div class="w-col w-col-3 w-col-small-3 w-col-tiny-3">
                  <div class="pricing-table-content" style="border-bottom: 2px solid #3a3a3a;">
                    <div class="pricing-card-row center">
                      <div class="pricing-icon table not-included"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="pricing-table-row-cols w-row">
                <div class="w-col w-col-3 w-col-small-3 w-col-tiny-3">
                  <div class="pricing-table-content" style="border-bottom: 2px solid #3a3a3a;">
                    <div class="pricing-card-row center">
                      <div class="pricing-icon table"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-show="monthlyContent" class="d-flex buttons-container">
          <div style="width: 17%"></div>
          <button v-show="monthlyContent" @click="goToStripeCheckout(user.gold_config.monthly.stripe_id, 'gold', 'monthly')" class="button-secondary button-secondary-custom" style="width: 11rem; background-color: #2d2d2d">
            {{ $t('components.plans.modals.otherPlansDesktop.card.footer.cta.gold') }}
          </button>
          <button v-if="computedPlanGold.gateway == 'guru'" v-show="quarterlyContent" @click="goToGuruCheckout(computedPlanGold.checkout_url, 'gold', 'quarterly')" class="button-secondary button-secondary-custom" style="width: 11rem; background-color: #2d2d2d">
            {{ $t('components.plans.modals.otherPlansDesktop.card.footer.cta.gold') }}
          </button>
          <button v-if="computedPlanGold.gateway == 'stripe'" v-show="quarterlyContent" @click="goToStripeCheckout(user.gold_config.quarterly.stripe_id, 'gold', 'quarterly')" class="btn-primary" style="width: 11rem; border-radius: 8px !important;">
            {{ $t('components.plans.modals.otherPlansDesktop.card.footer.cta.gold') }}
          </button>

          <button v-show="monthlyContent" @click="goToStripeCheckout(user.diamond_config.monthly.stripe_id, 'diamond', 'monthly')" class="btn-primary" style="width: 11rem; border-radius: 8px !important;">
            {{ $t('components.plans.modals.otherPlansDesktop.card.footer.cta.diamond') }}
          </button>
          <button v-if="computedPlanDiamond.gateway == 'guru'" v-show="quarterlyContent" @click="goToGuruCheckout(computedPlanDiamond.checkout_url, 'diamond', 'quarterly')" class="btn-primary" style="width: 11rem; border-radius: 8px !important;">
            {{ $t('components.plans.modals.otherPlansDesktop.card.footer.cta.diamond') }}
          </button>
          <button v-if="computedPlanDiamond.gateway == 'stripe'" v-show="quarterlyContent" @click="goToStripeCheckout(user.diamond_config.quarterly.stripe_id, 'diamond', 'quarterly')" class="btn-primary" style="width: 11rem; border-radius: 8px !important;">
            {{ $t('components.plans.modals.otherPlansDesktop.card.footer.cta.diamond') }}
          </button>
        </div>
      </div>
    </div>
  </modal>
</template>
  
<script>
export default {
    name: 'OtherPlansDesktop',
    props: {
      user: {
        type: Object,
      }
    },
    data(){
      return{
        selectedPlan: 'quarterly' 
        ,
        bigPriceDiamond: parseInt(this.user.diamond_config.quarterly.amount)
        ,
        bigPriceGold: parseInt(this.user.gold_config.quarterly.amount)
        ,
        quarterlyContent: true
        ,
        monthlyContent: false
        ,
      }
    },
    computed: {
      getUser() {
        return this.$store.getters.currentUser || {};
      },
      computedSelectedPlan() {
        return this.selectedPlan || 'quarterly';
      },
      computedPlanGold() {
        if (this.selectedPlan == 'quarterly') {
          return this.user.gold_config.quarterly;
        } else if (this.selectedPlan == 'monthly') {
          return this.user.gold_config.monthly;
        } else if (this.selectedPlan == 'semester') {
          return this.user.gold_config.semester;
        } else {
          return this.user.gold_config.yearly;
        }
      },
      computedPlanDiamond() {
        if (this.selectedPlan == 'quarterly') {
          return this.user.diamond_config.quarterly;
        } else if (this.selectedPlan == 'monthly') {
          return this.user.diamond_config.monthly;
        } else if (this.selectedPlan == 'semester') {
          return this.user.diamond_config.semester;
        } else {
          return this.user.diamond_config.yearly;
        }
      },
      computedHasTrial() {
        return this.user.gold_config.monthly && this.user.gold_config.monthly.trial_days > 0;
      },
      computedTrial() {
        return this.user.gold_config.monthly.trial_days;
      },
      computedDiscountText(){
        let plan;
        if (this.selectedPlan == 'quarterly') {
          plan = this.user.gold_config.quarterly;
        }else {
          plan = this.user.gold_config.monthly;
        }
        if (plan.coupon?.amount_off) {
          return `${this.computedFormattedCurrency}${plan.coupon?.amount_off}`
        } else {
          return `${plan.coupon?.percent_off}%`
        }
      },
      computedHasAffiliate(){
        return this.user.affiliate_name;
      },
      computedHasDiscount() {
        if (this.selectedPlan == 'quarterly') {
          return this.user.gold_config.quarterly.coupon;
        } else {
          return this.user.gold_config.monthly.coupon;
        }
      },
      computedFormattedCurrency() {
        if (this.user.currency == 'BRL') {
          return 'R$';
        } else if (this.user.currency == 'EUR') {
          return '€'
        } else if (this.user.currency == 'USD') {
          return 'US$'
        } else {
          return 'R$'
        }
      },
    },
    methods: {
      changePriceFromMonthly(){
        this.bigPriceDiamond = parseInt(this.user.diamond_config.quarterly.amount);
        this.bigPriceGold = parseInt(this.user.gold_config.quarterly.amount);
      },
      changePriceFromQuarterly(){
        this.bigPriceDiamond = parseInt(this.user.diamond_config.monthly.amount);
        this.bigPriceGold = parseInt(this.user.gold_config.monthly.amount);
      },
      showQuarterlyContent(){
        this.quarterlyContent = true;
        this.monthlyContent = false;
      },
      hideQuarterlyContent(){
        this.quarterlyContent = false;
        this.monthlyContent = true;
      },
      hideModalDesktop() {
        this.$modal.hide('otherplans-desktop');
      },
      successDesktop() {
        this.$emit('success');
      },
      goToStripeCheckout(stripe_id, plan_kind, occurrence) {
        let successUrl = `${process.env.VUE_APP_API_URL}/users/me/subscriptions/new?session_id={CHECKOUT_SESSION_ID}`
        let cancelUrl = `${process.env.VUE_APP_APP_URL}/plano-recomendado`

        const data = {
          mode: 'subscription',
          price_id: stripe_id,
          cancel_url: cancelUrl,
          success_url: `${process.env.VUE_APP_API_URL}/users/session_authorize/new?email=${this.user.email}&token=${this.user.token}&redirect=${successUrl}`,
        };

        this.$mixpanel.track('other_plans_subscribe_button_clicked', { 
          plan_kind: plan_kind,
          occurrence: occurrence,
        });
        this.isLoading = true;
        this.$store.dispatch('createCheckout', data);
      },
      goToGuruCheckout(checkout_url, plan_kind, occurrence) {
        this.$mixpanel.track('other_plans_subscribe_button_clicked', { 
          plan_kind: plan_kind,
          occurrence: occurrence,
        });
        window.location.href = `${checkout_url}?email=${this.user.email}&name=${this.user.name}`;
      },
    },
  }
  </script>

<style>
  .button-secondary-custom:hover{
    background-color: #333 !important;
  }

  .modal-content-desktop {
    height: 15rem !important;
    overflow: auto !important;
    background: #171717;
    border-radius: 8px;
  }
  
  .buttons-container{
    justify-content: space-around;
    margin-top: .3rem;
    padding-bottom: 4rem;
    background-color: #101010;
    padding-top: .5rem;
  }

  .otherplans-text{
    font-size: 1rem !important;
  }

  .plans-benefits{
    padding-top: 0.8rem;
    background-color: #181818;
  }

  .cross-line {
    background-color: rgba(231, 83, 65, .7);
    height: 2px;
    position: relative;
    top: -17px;
    width: 3rem;
  }

  .price-text{
    padding-left: .4rem;
    font-size: .7rem;
    color:#B5B3B3;
    margin-bottom: 0rem;
  }

  .bad-price{
    justify-content: flex-start;
    color: #B5B3B3;
  }
  .good-price{
    justify-content: flex-start;
  }

  .plan-container-title{
    margin-bottom: .2rem;
  }
  .plan-container{
    justify-content: space-around;
    padding-left: 1rem;
  }
  .gold-container{
    width: 40%;
    color: #fff;
  }
  .diamond-container{
    width: 40%;
    color: #fff;
  }
  .otherplans-modal-size{
    background-color: #101010 !important;
    border-radius: 8px !important;
    border: 1px solid #828282;
    border-top: unset ;
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    width: 950px !important;
    height: 29rem !important;
  }
  
  .otherplans-modal-body{
    width: 100%;
    padding-left: 0rem;
    padding-right: 0rem;
    padding-bottom: 0rem;
    padding-top: 0.5rem;
  }
  
  .modal-sub-header{
    justify-content: center !important;
    text-align: center !important;
    color:#ffffff;
    width: 70%;
    margin: auto;  
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 110%;
  }
  
  .modal-text{
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    display: flex;
    margin: auto !important;
    align-items: center !important;
    text-align: center;
    color: #B5B3B3;
    padding-top: 5px;
    width: 450px !important;
  }
  
  .otherplans-details{
    width: 100% !important;
    border-radius: 8px;
    margin: auto !important;
    color: #fff;
    background-color: #181818;
  }

  .otherplans-header{
    background-color: #101010;
    height: 6rem !important;
  }
  
  .text-span-4{
    color: #ffffff;
  }
  
  .modal-footer-custom{
    padding-top: 5px !important;
    text-align: center;
    margin: auto;
  }
  
  .vm--overlay {
    background: rgba(0,0,0,.8) !important;
    opacity: 1 !important;
  }
  
  .upper-tag{
    background: linear-gradient(180deg, #F29932 0%, #E75341 100%);
    text-align: center;
    color: #fff;
  }
  .upper-tag-affiliate{
    color: #E75341;
    text-align: center;
    background: #fff;
  }

  .vm--container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  </style>