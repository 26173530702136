<template>
  <div>
    <div class="input-group">
      <input ref="emailRef" v-model.trim="data.email" @keyup.enter="submit()" :disabled="token" type="email" class="form-control" required :placeholder="$t('components.auth.steps.email.placeholder')">
    </div>

    <p class="text-danger mb-0 mt-1" v-if="!$v.data.email.required && computedHasToValidate">{{ $t('components.auth.steps.email.validations.required') }}</p>
    <p class="text-danger mb-0 mt-1" v-else-if="!$v.data.email.email && computedHasToValidate">{{ $t('components.auth.steps.email.validations.invalid') }}</p>
    <p class="text-danger mb-0 mt-1" v-else-if="!computedEmailIsAvailable && computedHasToValidate">{{ $t('components.auth.steps.email.validations.exists') }}</p>

    <button @click="submit()" :disabled="computedIsLoading" class="btn w-100 btn-primary mt-4">
      <template v-if="computedIsLoading">
         <i class="fe fe-loader"></i> {{ $t('components.auth.steps.email.loading') }}
      </template>
      <template v-else>
        {{ $t('components.auth.steps.email.continue') }}
      </template>
    </button>
  </div>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators'

export default {
  name: 'EmailStep',
  props: {
    token: {
      type: String
    },
  },
  data() {
    return {
      data: {
        email: this.email || ''
      },
      interaction: {
        hasToValidate: false,
        emailIsAvailable: true,
        isLoading: false,
      }
    };
  },
  validations: {
    data: {
      email: {
        required,
        email,
      }
    }
  },
  computed: {
    computedHasTypedEmail() {
      return (this.data.email && this.data.email.length > 0) || false;
    },
    computedHasToValidate() {
      return this.interaction.hasToValidate || false;
    },
    computedEmailIsAvailable() {
      return this.interaction.emailIsAvailable;
    },
    computedIsLoading() {
      return this.interaction.isLoading || false;
    },
  },
  methods: {
    async submit() {
      if (this.token) {
        this.$emit('submit', this.data.email);
        return;
      }

      this.interaction.hasToValidate = true;

      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.interaction.isLoading = true;
        let isEmailAvailable = await this.checkEmailAvailability(this.data.email);
        this.interaction.isLoading = false;
        if (isEmailAvailable) {
          this.$emit('submit', this.data.email);
        } else {
          this.interaction.emailIsAvailable = false;
        }
      }
    },
    async checkEmailAvailability(email) {
      const res = await fetch(`${process.env.VUE_APP_API_URL}/api/users/check_email?email=${email}`);
      const data = await res.json();
      return data.is_available;
    },
  },
  mounted() {
    this.$refs.emailRef.focus();
  },
  watch: {
    'data.email': function() {
      this.interaction.hasToValidate = false;
      this.interaction.emailIsAvailable = this.computedHasTypedEmail;
    },
  },
}
</script>