<template>
  <div>
    <div class="input-group">
      <input ref="nameRef" v-model.trim="data.name" @keyup.enter="submit()" type="text" class="form-control" required :placeholder="$t('components.auth.steps.name.placeholder')">
    </div>

    <p class="text-danger mb-0 mt-1" v-if="!$v.data.name.required && computedHasToValidateRequired">{{ $t('components.auth.steps.name.validations.required') }}</p>

    <button @click="submit()" class="btn w-100 btn-primary mt-4">
      {{ $t('components.auth.steps.name.continue') }}
    </button>
  </div>
</template>
  
<script>
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'NameStep',
  props: {
    parentName: {
      type: String,
    },
  },
  data() {
    return {
      data: {
        name: this.name || this.parentName || ''
      },
      interaction: {
        hasToValidateRequired: false,
      }
    };
  },
  validations: {
    data: {
      name: {
        required,
      }
    }
  },
  computed: {
    computedHasTypedName() {
      return (this.data.name && this.data.name.length > 0) || false;
    },
    computedHasToValidateRequired() {
      return this.interaction.hasToValidateRequired || false;
    }
  },
  methods: {
    submit() {
      this.interaction.hasToValidateRequired = true;

      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.$emit('submit', { name: this.data.name });
      }
    },
  },
  mounted() {
    this.$refs.nameRef.focus();
  },
  watch: {
    'data.name': function() {
      this.interaction.hasToValidateRequired = this.computedHasTypedName;
    },
  },
}
</script>