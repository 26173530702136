<template>
  <div class="background-container image">
    <div class="container">
      <div class="card" >
        <div class="card-body-loading d-flex justify-content-center mb-4" style="flex-direction: column;">
          <img src="/img/extractor-logo-only.svg" alt="" class="logo-extension-only mb-4 mt-4">
          
          <div class="fake-loading">
            <h1 class="text-fake-loading">{{ $t('components.fakeLoading.title') }}<span class="ellipsis">.</span><span class="ellipsis2">.</span><span class="ellipsis3">.</span> </h1>
          </div>

            <div class="loading-container">
              <div class="loading-bar">
                <div class="loading-progress"></div>
              </div>
            </div>

        </div>
      </div>
    </div>
  </div>
  </template>
  
  <script>
  export default {
    name: 'PlanLoadingExtension',
    computed: {
      getUser() {
        return this.$store.getters.currentUser || {};
      },
    },
    mounted() {
      setTimeout(() => this.$router.push({ name: 'UserRegister', query:{ utm_source: 'extension' } }), 4000);
    },
  };
  </script>

<style lang="css" scoped>
  .text-fake-loading{
    color: white;
    text-align: center;
  }
  .logo-extension-only{
    height: 10rem;
  }

  @media screen and (min-width: 479px) {
    .container {
      padding-top: 7rem !important;
    }
  }
  @media screen and (max-width: 1900px) and (min-width: 600px) {
    .card {
      margin-top: -5rem !important;
      padding: 0px 40px 20px 40px !important;
    }
  }
  @media screen and (min-width: 1900px) {
    .card {
      margin-top: -3rem !important;
      padding: 0px 40px 20px 40px !important;
    }
  }

.card-body-loading{
  padding: 0px 0px 35px 0px !important;
}
.loading-container {
  width: 528px;
  height: 32px;
  border-radius: 6px;
  background-color: #e9e9e9;
  overflow: hidden;
}

.loading-bar {
  width: 100%;
  height: 100%;
  position: relative;
}

.loading-progress {
  width: 0;
  height: 100%;
  border-radius: 4px;
  background: linear-gradient(to right, #f29932, #e75341);
  animation: loadingAnimation 4s 1 forwards;
}

@keyframes loadingAnimation {
  0%, 25% {
    width: 0;
  }
  50% {
    width: 50%; /* Preenchimento até a metade da barra */
  }
  100% {
    width: 100%;
  }
}
.ellipsis {
  animation: showEllipsis 1.5s infinite;
  animation-delay: 0.5s;
  opacity: 0;
}
.ellipsis2 {
  animation: showEllipsis 1.5s infinite;
  animation-delay: 1s;
  opacity: 0;
}
.ellipsis3 {
  animation: showEllipsis 1.5s infinite;
  animation-delay: 1.5s;
  opacity: 0;
}

@keyframes showEllipsis {
  0%, 100% {
    opacity: .2;
  }
  25% {
    opacity: 1;
  }
}
    
</style>
