import ApiService from '../api.service';

const Users = {
  register: (params) => ApiService.post('users/sign_up', params),
  googleRegister: (params) => ApiService.post('users/google_sign_up', params),
  show: () => ApiService.get('users/me'),
  update: (params) => ApiService.put('users/me', params),
};

export default Users;
