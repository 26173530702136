import Checkouts from '@/api/resources/checkouts';

const state = {
  checkout: {},
};

const getters = {
  getCheckout: $state => $state.checkout,
};

const actions = {
  createCheckout({ commit }, payload) {
    return new Promise((resolve, reject) => {
      Checkouts.create(payload).then((response) => {
        commit('setCheckout', response);
        return resolve(response);
      })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

const mutations = {
  setCheckout($state, payload) {
    const stateCopy = $state;
    stateCopy.checkout = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
