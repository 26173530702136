import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

import UserRegister from "@/components/Register.vue";
import PlanLoading from "@/components/PlanLoading.vue";
import TrendsLoading from "@/components/TrendsLoading.vue";
import PlanLoadingExtension from "@/components/PlanLoadingExtension.vue";
import RecomendedPlan from "@/components/RecomendedPlan.vue";
import PlanComparison from "@/components/PlanComparison.vue";
import FakeLoading from "@/components/FakeLoading.vue";
import InfoPlan from "@/components/InfoPlan.vue";

export const router = new Router({
  routes: [
    {
      path: '/',
      name: 'UserRegister',
      component: UserRegister,
    },
    {
      path: '/calculando',
      name: 'PlanLoading',
      component: PlanLoading,
    },
    {
      path: '/teste-gratis-trends',
      name: 'TrendsLoading',
      component: TrendsLoading,
    },
    {
      path: '/calculando-ext',
      name: 'PlanLoadingExtension',
      component: PlanLoadingExtension,
    },
    {
      path: '/comparacao-de-plano',
      name: 'PlanComparison',
      component: PlanComparison,
    },
    {
      path: '/plano-recomendado',
      name: 'RecomendedPlan',
      component: RecomendedPlan,
    },
    {
      path: '/ext-installing',
      name: 'FakeLoading',
      component: FakeLoading,
    },
    {
      path: '/info-plan',
      name: 'InfoPlan',
      component: InfoPlan,
    },
  ]
});
