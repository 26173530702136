import Vue from 'vue'
import './plugins';

import App from './App.vue'
import { router } from './router'
import store from './store'
import i18n from "./i18n";

import VueCookie from 'vue-cookie';

import VueGtag from "vue-gtag";

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';

import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

import PrettyCheckbox from 'pretty-checkbox-vue';
import 'pretty-checkbox/dist/pretty-checkbox.css';

import 'vue-phone-number-input/dist/vue-phone-number-input.css';

import Vuelidate from 'vuelidate';

import VueMixpanel from 'vue-mixpanel';
import Hotjar from 'vue-hotjar';

// import CrispChat from '@dansmaculotte/vue-crisp-chat'
import Segment from '@dansmaculotte/vue-segment'

import VModal from 'vue-js-modal/dist/index.nocss.js'
import 'vue-js-modal/dist/styles.css'

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(Toast, {});
Vue.use(PrettyCheckbox);
Vue.use(Vuelidate);
Vue.use(VueCookie, { domain: '.adminer.pro' });
Vue.use(VueGtag, { config: { id: "UA-173435206-1" } });
Vue.use(VueMixpanel, {
  token: '0af857f7a94003c0783978582c67ee0c'
});
Vue.use (Hotjar, {
  id: '3315300',
  isProduction: true,
  snippetVersion: 6
});
Vue.use(VModal);
// Vue.use(CrispChat, {
//   websiteId: 'bedba867-f8f1-4ce7-af4c-7116a74d5219',
//   disabled: false,
//   hideOnLoad: false
// })

Vue.use(Segment, {
  writeKey: 'GqX34h4UdgUwtWYzHekNVl2K53kNwzAz'
})

new Vue({
  components: {
    App,
  },
  router,
  store,
  i18n,
  template: '<App/>'
}).$mount('#app')
