<template>
  <div class="background-container h-100">
    <crisp-script v-if="getUser.id" :user="getUser" />
    <silver-plan v-if="getUser.id && getUser.recomended_plan == 'silver'" :user="getUser" />
    <gold-plan v-if="getUser.id && getUser.recomended_plan == 'gold'" :user="getUser" />
    <diamond-plan v-if="getUser.id && getUser.recomended_plan == 'diamond' && getUser.utm_source != 'trends'" :user="getUser" />
    <trends-plan v-if="getUser.id && getUser.recomended_plan == 'diamond' && getUser.utm_source == 'trends'" :user="getUser" />
  </div>
</template>

<script>
import CrispScript from '@/components/common/CrispScript.vue';
import SilverPlan from '@/components/plans/Silver.vue';
import GoldPlan from '@/components/plans/Gold.vue';
import DiamondPlan from '@/components/plans/Diamond.vue';
import TrendsPlan from '@/components/plans/Trends.vue';

export default {
  name: 'RecomendedPlan',
  components: {
    CrispScript,
    SilverPlan,
    GoldPlan,
    DiamondPlan,
    TrendsPlan
  },
  data() {
    return {
      user: null,
    };
  },
  computed: {
    getUser() {
      return this.$store.getters.currentUser || {};
    },
  },
  mounted() {
   this.scrollToTop()
   
    this.$store.dispatch('fetchUser').then((user) => {
      if (!user.id) {
        this.$router.push({ name: 'UserRegister' });
      }
    });

    const user = this.getUser;
    switch (user.recomended_plan) {
      case 'silver':
        this.$mixpanel.track('register_front_recommended_plan', { email: user.email, plan: 'silver' });
        break;
      case 'gold':
        this.$mixpanel.track('register_front_recommended_plan', { email: user.email, plan: 'gold' });
        break;
      case 'diamond':
        this.$mixpanel.track('register_front_recommended_plan', { email: user.email, plan: 'diamond' });
        break;
    
      default:
        break;
    }

    if(user && user.id){
      let sales_range = user.sales_range
      if(!sales_range[0]) {
        sales_range[0] = 0
      }

      if(!sales_range[1]) {
        sales_range[1] = 0
      }
    }
    
  },
  methods: { 
    scrollToTop() {
      window.scrollTo(0,0);
    }
  }
};
</script>

<style lang="scss">
body {
  background-color: #0A0A0A !important;
  background-image: none !important;
}

* {
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
}

strong {
  font-weight: 700;
}

h1 {
  margin: 0;
}

img {
  max-width: 100%;
  vertical-align: middle;
}

.price-description-text {
  display: flex;
  flex-direction: column;
  position: relative;
  top: 20px;
  gap: 1px;
}

.inherited-styles-for-exported-element {
  color: #333;
  font-family: Roboto, sans-serif;
  font-size: 1rem;
  line-height: 1.5;
}

.w-button, img {
  border-style: initial;
  border-width: 0;
  display: inline-block;
}

.w-button {
  cursor: pointer;
  text-decoration: none;
}

.w-col {
  float: left;
  min-height: 1px;
  padding-left: 10px;
  padding-right: 10px;
  position: relative;
}

.w-col-4 {
  width: 33.3333%;
}

@media screen and (max-width: 767px) {
  .w-row {
    margin-left: 0;
    margin-right: 0;
  }

  .w-col {
    left: auto;
    right: auto;
    width: 100%;
  }

  .w-col-small-4 {
    width: 33.3333%;
  }
}

@media screen and (max-width: 479px) {
  .w-col {
    width: 100%;
  }

  .w-col-tiny-4 {
    width: 33.3333%;
  }
}

.button-primary {
  -webkit-box-align: center;
  -webkit-box-pack: center;
  align-items: center;
  border-radius: .5rem;
  justify-content: center;
  line-height: 1.1;
}

.button-primary, .text-align-center {
  text-align: center;
}

.text-align-left {
  text-align: left;
}

.padding-global {
  display: block;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.container-medium {
  max-width: 73.75rem;
  width: 100%;
}

.text-weight-medium {
  font-weight: 500;
}

.text-size-tiny {
  display: inline-block;
  font-family: Roboto, sans-serif;
  font-size: .75rem;
  line-height: 1.5;
  margin-right: .5rem;
  margin-left: 1rem;
}

.text-size-tiny-custom {
  display: inline-block;
  font-family: Roboto, sans-serif;
  font-size: .75rem;
  line-height: 1.5;
}

.text-size-small {
  -webkit-box-align: center;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-box-pack: justify;
  align-items: center;
  display: block;
  flex-direction: column;
  font-size: .875rem;
  justify-content: space-between;
  line-height: 1.5;
}

.heading-style-h2 {
  font-size: 2rem;
  font-weight: 500;
  line-height: 1.3;
}

.heading-style-h5 {
  color: #fff;
  font-size: 1.375rem;
  font-weight: 500;
  line-height: 1.1;
}

.text-color-gradiente-0 {
  -webkit-text-fill-color: transparent;
  background-clip: text;
  background-image: linear-gradient(#f29932, #e75341);
}

.display-inline {
  display: inline;
}

.align-center {
  margin-left: auto;
  margin-right: auto;
}

.text-color-light {
  color: #fff;
}

.text-color-light-3 {
  color: #b5b3b3;
}

.heading_wrap {
  -webkit-box-align: center;
  align-items: center;
}

.pricing-table-row-title {
  max-width: 30rem;
  padding-left: 0;
  padding-right: 32px;
  position: relative;
  width: 63%;
  z-index: 1;
}

.pricing-title {
  font-size: 24px;
  font-weight: 500;
  line-height: 34px;
  text-align: center;
  text-decoration: none;
  text-transform: capitalize;
  z-index: 4;
}

.pricing-table-cols {
  padding-left: 0;
  padding-right: 0;
}

.pricing-icon {
  -webkit-box-flex: 0;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  flex: 0 0 auto;
}

.pricing-table-header-cols {
  -webkit-box-align: start;
  -webkit-box-direction: normal;
  -webkit-box-flex: 1;
  -webkit-box-orient: horizontal;
  -webkit-box-pack: start;
  align-items: flex-start;
  background-color: #101010;
  border-top-right-radius: 16px;
  display: block;
  flex: 1 1 0;
  flex-direction: row;
  justify-content: flex-start;
}

.toggle-text {
  cursor: pointer;
  display: inline-block;
  font-weight: 500;
  padding-bottom: 16px;
  padding-top: 16px;
  position: relative;
  text-align: center;
  width: 50%;
  z-index: 1;
}

.pricing-card-row {
  -webkit-box-align: center;
  align-items: center;
  border-bottom-color: rgba(255, 255, 255, .08);
  border-bottom-width: 1px;
  display: flex;
  padding-left: 2px;
  position: relative;
  z-index: 1;
}

.toggle-btn {
  border-radius: 4px;
  bottom: 8px;
  display: block;
  height: 40px;
  left: 8px;
  position: absolute;
  top: 8px;
  z-index: 0;
}

.pricing-row-text {
  color: #fff;
  font-weight: 400;
  text-align: left;
  text-transform: capitalize;
}

.pricing-currency {
  font-weight: 500;
  position: absolute;
  text-align: center;
}

.pricing-table-header {
  -webkit-box-align: center;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-box-pack: center;
  align-items: center;
  background-color: #101010;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  position: sticky;
  top: 0;
  z-index: 2;
}

.pricing-table-row-cols {
  -webkit-box-flex: 1;
  display: block;
  flex: 1 1 0;
}

.pricing-card-header {
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  display: flex;
  flex-direction: column;
  position: relative;
}

.pricing-table-row, .toggle-wrapper {
  -webkit-box-align: center;
  align-items: center;
  display: flex;
}

.toggle-wrapper {
  -webkit-box-pack: center;
  justify-content: center;
}

.pricing-table-row {
  border-bottom: 1px solid #2d2d2d;
  margin-top: 0;
  padding-bottom: .5rem;
  padding-top: 2.5rem;
}

.pricing-price {
  font-weight: 500;
  text-transform: capitalize;
}

.pricing-table-content {
  padding-left: 32px;
  padding-right: 32px;
  position: relative;
  width: 13rem;
  z-index: 1;
}

.pricing-card-price-block {
  position: relative;
}

.pricing-table-price-block {
  -webkit-box-align: center;
  -webkit-box-direction: normal;
  -webkit-box-orient: horizontal;
  -webkit-box-pack: start;
  align-items: center;
  column-gap: 0;
  display: block;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 0;
  padding-left: 1rem;
  padding-top: 1rem;
  position: relative;
}

.pricing-small-text {
  color: #5b5d63;
  font-size: 12px;
  text-transform: capitalize;
}

.toggle-bg {
  border-radius: 8px;
  height: 56px;
  perspective: 1000px;
  position: relative;
  transform: perspective(1000px);
}

.pricing-table-wrapper {
  -webkit-box-direction: normal;
  -webkit-box-orient: horizontal;
  background-color: #181818;
  border-radius: 16px;
  display: block;
  flex-direction: row;
  max-width: 50rem;
  position: relative;
  width: 81%;
}

.plan_component, .section_plan {
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-box-pack: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.section_plan {
  -webkit-box-align: stretch;
  align-items: stretch;
  min-height: 100vh;
  padding-bottom: 4rem;
  padding-top: 4rem;
  width: 100%;
}

.plan_component {
  -webkit-box-align: center;
  align-items: center;
  row-gap: 2rem;
}

.plan_image-logo {
  margin-left: .4rem;
  margin-top: -.7rem;
  width: 2.9rem;
}

.plan_title-custom {
  -webkit-box-align: center;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.text-style-custom {
  color: #b5b3b3;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}

.plan_title-description-wrap {
  max-width: 54.7rem;
  width: 72%;
}

.column1-silver, .column2-gold {
  display: none;
}

.column3-diamond {
  display: block;
}

.pricing-table-cols-copy {
  padding-left: 0;
  padding-right: 0;
}

.pricing_wrap-data {
  display: flex;
  row-gap: .25rem;
}

.buttons_wrap {
  -webkit-box-align: stretch;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-box-pack: end;
  align-items: stretch;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  right: 3.5rem;
  row-gap: 1rem;
}

.pricing_wrap-description {
  padding: 0 3rem 2rem;
}

.text-description {
  color: rgba(255, 255, 255, .5);
  font-size: .9rem;
  font-weight: 400;
  line-height: 1.1;
  text-align: left;
  text-transform: capitalize;
}

.text-span-2, .text-span-3 {
  color: #fff;
}

.off_wrap, .pricing_wrap-header-content {
  -webkit-box-align: center;
  align-items: center;
  width: 100%;
}

.off_wrap {
  -webkit-box-pack: center;
  justify-content: center;
}

.pricing_wrap-header-content {
  display: flex;
  height: 8rem;
  justify-content: space-around;
  overflow: hidden;
  padding-bottom: none;
  padding-top: none;
}

.price_wrap-money-bad {
  margin-left: .375rem;
}

.line {
  background-color: rgba(231, 83, 65, .7);
  height: 2px;
  position: relative;
  top: -20px;
  width: 100%;
}

.price_wrap-money-nice {
  margin-left: -.5rem;
  display: flex;
}

.description_wrap-money {
  margin-top: -.5rem;
}

.wrap-content {
  -webkit-box-align: center;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  align-items: center;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}

@media screen and (min-width: 1280px) {
  .text-size-tiny {
    display: inline-block;
  }

  

  .pricing-table-price-block {
    margin-top: 0;
    padding-left: .9rem;
    padding-top: 1rem;
  }

  .section_plan {
    padding-top: 4rem;
  }

  .plan_image-logo {
    margin-left: .5rem;
    margin-top: -.9rem;
    width: 2.9rem;
  }

  .plan_title-description-wrap {
    width: 80%;
  }

  .pricing_wrap-header-content {
    height: 8rem;
  }

  .price_wrap-money-bad {
    -webkit-box-align: start;
    align-items: flex-start;
    display: block;
    padding-right: 0;
  }

  .line {
    background-color: rgba(231, 83, 65, .7);
    height: 2px;
    left: .1rem;
    padding-bottom: 0;
    padding-right: 0;
    position: relative;
    top: -1.2rem;
    width: 100%;
  }

  .price_wrap-money-nice {
    -webkit-box-align: center;
    -webkit-box-direction: normal;
    -webkit-box-orient: vertical;
    align-items: center;
    column-gap: .25rem;
    display: flex;
    flex-direction: row;
  }
  .money-discount-text{
    margin-right: 0px !important;
  }

  .description_wrap-money {
    padding-left: 0;
  }
}
  @media screen and (max-width: 479px){
    .plan_component {
      row-gap: .8rem !important;
  }
}

@media screen and (min-width: 1440px) {
  .plan_component {
    row-gap: 2rem;
  }

  .plan_image-logo {
    width: 3rem;
  }
}

@media screen and (min-width: 1920px) {
  .section_plan {
    padding-top: 4rem;
  }

  .plan_component {
    row-gap: 2rem;
  }

  .plan_image-logo {
    margin-top: -1.2rem;
    width: 3.2rem;
  }

  .wrap-content {
    row-gap: 1.3rem;
  }
}

@media screen and (max-width: 991px) {
  .heading-style-h2 {
    font-size: 2.5rem;
  }

  .heading-style-h5 {
    text-align: left;
  }

  .align-center {
    text-align: center;
  }

  .pricing-table-row-title {
    background-color: #181818;
    color: #2d2d2d;
    padding-left: 0;
    padding-right: 0;
    width: 100%;
  }

  .pricing-title {
    font-size: 20px;
    line-height: 24px;
  }

  .pricing-table-header-cols {
    background-color: #0f0f0f;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    position: relative;
  }

  .pricing-table-category,   .pricing-table-header {
    -webkit-box-direction: normal;
    -webkit-box-orient: vertical;
    flex-direction: column;
  }

  .pricing-table-category {
    -webkit-box-align: start;
    align-items: flex-start;
    display: block;
    margin-top: 0;
  }

  .pricing-table-header {
    background-color: #0f0f0f;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    padding-bottom: 0;
    padding-top: 0;
    top: 0;
    z-index: 2;
  }

  .pricing-table-row {
    -webkit-box-align: center;
    -webkit-box-direction: normal;
    -webkit-box-orient: horizontal;
    -webkit-box-pack: start;
    align-items: center;
    border-bottom: 1px solid #2d2d2d;
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom: 0;
    padding-bottom: .5rem;
    padding-top: 2rem;
  }

  .pricing-table-content {
    border-bottom: 1px none rgba(255, 255, 255, .08);
    padding-bottom: 0;
    padding-top: 0;
  }

  .pricing-small-text {
    font-size: 10px;
  }

  .pricing-table-wrapper {
    overflow: visible;
    padding-bottom: 0;
    padding-top: 0;
    width: 100%;
  }

  .section_plan {
    padding-top: 4rem;
  }

  .plan_image-logo {
    margin-top: -.6rem;
  }

  .plan_title-description-wrap {
    width: 83%;
  }

  .buttons_wrap {
    align-content: space-around;
    display: flex;
    flex-wrap: nowrap;
    right: 2.1rem;
    z-index: 2;
  }

  .pricing_wrap-description {
    padding-bottom: 2rem;
    padding-top: 2rem;
  }

  .text-description {
    font-size: .875rem;
    line-height: 18px;
  }

  .pricing_wrap-header-content {
    padding-bottom: 1rem;
    padding-top: 1rem;
  }
  .price-description-text {
    display: flex;
    flex-direction: column;
    margin-left: .1rem;
  }
  .text-size-tiny-custom{
    white-space: nowrap;
  }
}

@media screen and (max-width: 767px) {
  .padding-global {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .heading-style-h5 {
    font-size: 1.125rem;
  }

  .pricing-table-row-title {
    width: 100%;
  }

  .pricing-table-header {
    -webkit-box-direction: normal;
    -webkit-box-orient: vertical;
    flex-direction: column;
    padding-top: 0;
  }

  .toggle-wrapper {
    margin-left: -24px;
    margin-right: -24px;
    margin-top: 8px;
  }

  .pricing-table-content {
    width: 12.8rem;
  }

  .pricing-table-price-block {
    margin-left: 0;
    padding-left: 0;
  }

  .plan_component {
    -webkit-box-align: center;
    -webkit-box-pack: center;
    align-items: center;
    display: flex;
    justify-content: center;
    row-gap: 3rem;
  }

  .plan_image-logo {
    margin-left: .4rem;
    margin-top: -.4rem;
    width: 2.3rem;
  }

  .text-style-custom {
    font-size: 1rem;
  }

  .plan_title-description-wrap {
    width: 84%;
  }

  .buttons_wrap {
    -webkit-box-direction: reverse;
    -webkit-box-orient: horizontal;
    column-gap: 1rem;
    flex-direction: row-reverse;
    right: auto;
  }

  .pricing_wrap-description {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .text-description {
    font-size: .875rem;
  }

  .pricing_wrap-header-content {
    -webkit-box-direction: normal;
    -webkit-box-orient: vertical;
    flex-direction: column;
    height: auto;
    padding-top: 2rem;
  }
}
@media screen and (max-width: 479px){
  .pricing_wrap-header-content {
    -webkit-box-direction: normal;
    -webkit-box-orient: vertical;
    flex-direction: column;
    height: auto;
    padding-top: .4rem;
  }
  .text-style-custom {
    color: #b5b3b3;
    font-size: .8rem !important;
    font-weight: 400;
    line-height: 1.5;
  }
  .heading-style-h2 {
    font-size: 1.6rem !important;
    font-weight: 500;
    line-height: 1.3;
  }
}

@media screen and (max-width: 479px) {
  .pricing-table-row-title {
    max-width: 29rem;
    width: 100%;
  }

  .pricing-row-text {
    text-align: center;
  }

  .pricing-card-header {
    margin-bottom: 0;
  }

  .pricing-table-row {
    -webkit-box-direction: normal;
    -webkit-box-orient: vertical;
    -webkit-box-pack: end;
    flex-direction: column;
    justify-content: flex-end;
  }

  .pricing-table-content {
    padding-left: 8px;
    padding-right: 8px;
  }

  .pricing-table-price-block {
    -webkit-box-direction: normal;
    -webkit-box-orient: horizontal;
    flex-direction: row;
  }

  .section_plan {
    padding-top: 1.5rem;
  }

  .plan_component {
    row-gap: 3rem;
  }

  .plan_image-logo {
    margin-left: 0;
    margin-top: -.5rem;
    width: 2.1rem;
  }

  .text-style-custom {
    font-size: 1rem;
  }

  .plan_title-description-wrap {
    width: 100%;
  }

  .buttons_wrap {
    -webkit-box-direction: reverse;
    -webkit-box-orient: horizontal;
    flex-direction: row-reverse;
  }

  .pricing_wrap-description {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .text-description {
    font-size: 14px;
    text-align: center;
  }

  .wrap-content {
    row-gap: .8rem;
  }
}

a:active {
  outline: 0;
}

.w-row::before {
  content: " ";
  display: table;
  grid-column-end: 2;
  grid-column-start: 1;
  grid-row-end: 2;
  grid-row-start: 1;
}

.w-row::after {
  clear: both;
}

.button-primary.is-small {
  font-size: 1rem;
  font-weight: 500;
  width: 9rem;
}

.button-primary.is-light {
  background-color: #fff;
  background-image: linear-gradient(#f29932, #e75341);
  color: #fff;
  padding-bottom: .8rem;
  padding-top: .8rem;
}

.button-secondary{
  background-color: #2D2D2D00;
  color: #fff;
  padding-bottom: .7rem;
  padding-top: .7rem;
  border-radius: 0.5rem;
  border: unset;
  width: 9rem;
}

.button-secondary:hover{
  background-color: #343434;
}

.text-size-small.text-align-center {
  text-align: center;
}

.heading_wrap.text-color-light {
  display: block;
}

.pricing-title.table {
  color: #fff;
  margin-bottom: 10px;
}

.pricing-table-cols.gold {
  display: none;
}

.pricing-table-cols.diamond {
  -webkit-box-pack: justify;
  align-content: center;
  column-gap: 16px;
  display: block;
  flex-wrap: wrap;
  grid-auto-columns: 1fr;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  row-gap: 16px;
}

.pricing-icon.table {
  background-image: url(https://uploads-ssl.webflow.com/63a1b54c23a6b7879791f4a4/63a4e73aa711d82e2ec719d3_incluso.png);
  background-size: 16px;
  height: 1rem;
  margin-bottom: 2px;
  margin-right: 0;
  margin-top: 2px;
  width: 1rem;
}

.toggle-text.monthly {
  color: #fff;
}

.toggle-text.yearly {
  color: #fff !important;
  padding-left: 8px;
}

.toggle-text.active {
  color: #f29932 !important;
}

.pricing-card-row.center {
  -webkit-box-align: stretch;
  -webkit-box-pack: center;
  align-items: stretch;
  justify-content: center;
  padding-left: 0;
}

.pricing-card-row.center, .pricing-card-row.mobile-center {
  border-bottom-style: none;
  padding-bottom: 0;
  padding-top: 0;
}

.toggle-btn.table {
  background-color: rgba(255, 255, 255, .1);
  width: 110px;
}

.pricing-row-text.title {
  font-size: 1rem;
  line-height: 1.5rem;
}

.pricing-currency.v2 {
  color: #b5b3b3;
  font-size: .9375rem;
  left: .25rem;
  line-height: 1.3em;
  margin-left: 0;
  margin-right: 0;
  padding-left: 0;
  padding-right: 0;
  top: 1.2375rem;
}

.pricing-card-header.table {
  -webkit-box-align: start;
  align-items: flex-start;
  overflow: visible;
  padding: 2rem 0 2rem;
  row-gap: 0;
  width: 19.4rem;
  z-index: 2;
}

.toggle-wrapper.table {
  -webkit-box-flex: 0;
  background-color: #101010;
  flex: 0 0 auto;
  max-width: 18rem;
  padding: 0 1rem;
  position: sticky;
  top: 0;
  width: 45%;
  z-index: 2;
}

.pricing-price.v2 {
  line-height: 42px;
}

.pricing-card-price-block.table {
  margin-right: 0;
}

.pricing-table-price-block.custom-pricing {
  column-gap: 0;
  margin-bottom: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-top: 0;
}

.pricing-small-text.v2 {
  font-weight: 700;
  line-height: 16px;
  text-align: left;
}

.toggle-bg.table {
  background-color: #242424;
  margin-left: 0;
  margin-right: 0;
  width: 256px;
}

.pricing-table-cols-copy.silver {
  display: none;
}

.price_yearly.text-color-light-3 {
  column-gap: 0rem;
  display: flex;
}

.price_monthly.text-color-light-3 {
  display: block;
  margin-left: .375rem;
}

.pricing_wrap-data.text-align-left {
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  flex-direction: column;
}

.off_wrap.text-color-light {
  background-color: #6b6b6b;
  background-image: linear-gradient(#f29932, #e75341);
  display: flex;
  height: 2.5rem;
  opacity: 1;
}

@media screen and (min-width: 1280px) {
  .pricing-currency.v2 {
    top: 1.5375rem;
  }

  .price_yearly.text-color-light-3 {
    column-gap: 0rem;
    display: flex;
  }

  .price_monthly.text-color-light-3 {
    display: block;
  }
}

@media screen and (min-width: 1920px) {
  .heading-style-h2.display-inline {
    font-size: 3.2rem;
  }

  .toggle-wrapper.table {
    background-color: #101010;
  }

  .off_wrap.text-color-light {
    background-image: linear-gradient(#f29932, #e75341);
    height: 2.5rem;
  }
}

@media screen and (max-width: 991px) {
  .button-primary.is-light {
    font-size: 1rem;
    line-height: 1.3;
    padding-bottom: 1rem;
    padding-top: 1rem;
  }

  .pricing-card-row.center {
    padding-bottom: 0;
    padding-top: 0;
  }

  .pricing-card-row.mobile-center {
    -webkit-box-pack: start;
    justify-content: flex-start;
    padding-bottom: 0;
    padding-top: 0;
  }

  .pricing-row-text.title {
    font-size: 16px;
    font-weight: 500;
  }

  .pricing-card-header.table {
    left: auto;
    padding-bottom: 2rem;
    padding-left: 1rem;
    padding-top: 5rem;
    position: relative;
  }

  .toggle-wrapper.table {
    border-radius: 16px;
    max-width: 14.9rem;
    padding-left: 0;
    padding-right: 0;
    width: 74%;
  }

  .toggle-bg.table {
    height: 3rem;
    max-width: 12.5rem;
    width: 85%;
  }

  .price_monthly.text-color-light-3 {
    display: block;
  }
}

@media screen and (max-width: 767px) {
  .button-primary.is-light {
    font-size: .875rem;
    line-height: 1.5;
    padding-bottom: 1rem;
    padding-top: 1rem;
  }

  .heading-style-h2.display-inline {
    font-size: 2rem;
  }

  .heading-style-h5.responsive-style-align-center {
    -webkit-box-pack: center;
    display: block;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
  }

  .pricing-card-row.mobile-center {
    padding-left: 0;
  }

  .pricing-currency.v2 {
    font-size: 14px;
    left: -.6rem;
    line-height: 20px;
  }

  .monthly-price-sign{
    left: -1.4rem !important;
  }
  .monthly-price-sign-gold{
    left: -1.3rem !important;
  }

  .pricing-card-header.table {
    padding-top: 2rem;
  }

  .toggle-wrapper.table {
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    padding-right: 0;
  }

  .pricing-price.v2 {
    line-height: 38px;
  }

  .price_yearly.text-color-light-3 {
    display: flex;
  }

  .price_monthly.text-color-light-3 {
    display: block;
  }

  .pricing_wrap-data.text-align-left {
    -webkit-box-align: start;
    -webkit-box-pack: center;
    align-items: flex-start;
    justify-content: center;
  }
}

@media screen and (max-width: 479px) {
  .button-primary.is-light {
    padding-bottom: .8rem;
    padding-top: .8rem;
  }

  .text-size-small.text-align-center {
    font-size: .75rem;
  }

  .heading-style-h2.display-inline {
    width: 100%;
  }

  .heading_wrap.text-color-light {
    display: block;
  }

  .pricing-title.table {
    font-size: 14px;
    line-height: 18px;
  }

  .pricing-card-row.mobile-center {
    -webkit-box-pack: center;
    justify-content: center;
  }

  .pricing-card-header.table {
    -webkit-box-align: center;
    align-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 8px;
    width: 17.5rem;
  }

  .toggle-wrapper.table {
    margin-left: 0;
    margin-right: 0;
    padding-right: 0;
    width: 100%;
  }

  .pricing-table-row.responsive-style-align-center {
    margin-left: auto;
    margin-right: auto;
  }

  .pricing-price.v2 {
    line-height: 30px;
  }

  .off_wrap.text-color-light {
    height: 3rem;
    padding: 0 37px;
  }
}

.button-primary.is-light:hover {
  background-image: linear-gradient(#df8d2e, #c84b3b);
}

.heading_wrap.text-color-light.text-align-center {
  width: fit-content;
}

.pricing-icon.table.not-included {
  background-image: url(https://uploads-ssl.webflow.com/63a1b54c23a6b7879791f4a4/63a4e73aa711d841a3c719d2_Cross-Mute.svg);
  background-size: 10px;
}

.pricing-card-row.last.center {
  margin-bottom: 0;
  padding-bottom: 0;
  padding-top: 0;
}

.pricing-row-text.title.monthly {
  display: none;
}

.pricing-card-header.table.middle {
  background-color: #242424;
  border-radius: 0;
  padding-bottom: 55px;
}

.pricing-price.v2.yearly-price {
  color: #41f3b3;
  display: inline-block;
  font-size: 2rem;
  text-align: left;
}

.pricing-price.v2.monthly-price {
  color: #e75341;
  font-size: 30px;
  text-align: left;
}

@media screen and (min-width: 1280px) {
  .pricing-price.v2.yearly-price {
    color: #41f3b3;
    display: inline-block;
    font-size: 1.875rem;
    padding-left: 0;
  }

  .pricing-price.v2.monthly-price {
    color: #e75341;
  }
}

@media screen and (min-width: 1920px) {
  .heading_wrap.text-color-light.text-align-center {
    width: fit-content;
  }
}

@media screen and (max-width: 991px) {
  .heading_wrap.text-color-light.text-align-center {
    width: fit-content;
  }

  .pricing-card-header.table.middle {
    background-color: transparent;
    padding-bottom: 10px;
  }
}

@media screen and (max-width: 767px) {
  .pricing-table-price-block.custom-pricing.responsive-style-align-center {
    margin-left: auto;
    margin-right: auto;
    padding-left: 2.5rem;
  }
}

@media screen and (max-width: 479px) {
  .button-primary.is-light.is-small {
    margin-top: 0;
  }

  .plan_component{
    row-gap: .6rem !important;
  }

  .section_plan{
    padding-top: 1.5rem !important;
  }

  .text-style-custom{
    font-size: .8rem !important;
  }

  .heading-style-h2.display-inline {
    font-size: 1.6rem !important;
  }

  .heading_wrap.text-color-light.text-align-center {
    width: fit-content;
  }

  .pricing-card-header.table.middle {
    padding-bottom: 0;
  }
}

.pricing-price.v2.yearly-price.style-custom {
  color: #ffffff;
  font-size: 1.875rem;
  font-weight: 400;
}

@media screen and (min-width: 1280px) {
  .pricing-price.v2.yearly-price.style-custom {
    color: #ffffff;
    font-size: 1.875rem;
    font-weight: 400;
    text-decoration: none;
  }
}

.container-medium {
  margin-left: auto !important;
  margin-right: auto !important;
}

html {
  font-size: 20px;
  /* This is IMPORTANT since some copied values use "REM" units */
}
</style>