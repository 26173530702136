/* eslint-disable */
import Vue from 'vue';
import Vuex from 'vuex';

import Error from './modules/error';
import Toast from './modules/toast';
import Loading from './modules/loading';
import User from './modules/user';
import Session from './modules/session';
import Checkout from './modules/checkout';

Vue.use(Vuex);

export default new Vuex.Store({
	modules: {
		Error,
		Toast,
		Loading,
		User,
		Session,
		Checkout,
	},
});
