<template>
  <div ref="googleLoginBtn">
  </div>
</template>
<script>
export default {
  mounted() {
    const gClientId = process.env.VUE_APP_GOOGLE_CLIENT_ID;
    const isMobile = () => {
      const mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
      if (mobile) {
        return 200
      } else {
        return 400
      }
    }

    window.google.accounts.id.initialize({
      client_id: gClientId,
      callback: this.handleCredentialResponse,
      auto_select: true
    })
    window.google.accounts.id.renderButton(
    this.$refs.googleLoginBtn, {
        text: 'signup_with', // or 'signup_with' | 'continue_with' | 'signin'
        size: 'large', // or 'small' | 'medium' | 'large' 
        width: isMobile(), // max width 400
        theme: 'filled_black', // or 'filled_black' |  'filled_blue' | 'outline'
        logo_alignment: 'left' // or 'center'
      }
    );
  },
  methods: {
    async handleCredentialResponse(response) {
      this.$emit('googleBtnCallback', response);
    },
  }
}
</script>