<template>
  <div @keyup.capture="onKeyUp">
    <div class="input-group">
      <VuePhoneNumberInput 
        class="mb-2"
        v-model="data.phone" 
        color="#f29932" 
        valid-color="#3acf5b" 
        error-color="#c84b3b"
        size="lg"
        ref="phoneRef"
        :translations="computedTranslations"
        :error="false" 
        :dark="true" 
        :no-example="false" 
        :no-flags="false"
        @update="handlePhoneUpdated"
        @keyup.enter="submit()"
      />
    </div>

    <p class="text-danger mb-0 mt-4" v-if="!$v.data.phone.required && computedHasToValidateRequired">{{ $t('components.auth.steps.phone.validations.required') }}</p>
    <p class="text-danger mb-0 mt-4" v-else-if="!computedValidPhone && computedHasToValidateRequired">{{ $t('components.auth.steps.phone.validations.invalid') }}</p>

    <button @click="submit()" class="btn w-100 btn-primary mt-5">
      {{ $t('components.auth.steps.phone.continue') }}
    </button>
  </div>
</template>
  
<script>
import VuePhoneNumberInput from "vue-phone-number-input";
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'PhoneStep',
  components: {
    VuePhoneNumberInput,
  },
  data() {
    return {
      data: {
        phone: null,
        formattedPhone: null,
      },
      interaction: {
        hasToValidateRequired: false,
        validPhone: false,
      }
    };
  },
  validations: {
    data: {
      phone: {
        required,
      }
    }
  },
  computed: {
    computedHasTypedPhone() {
      return (this.data.phone && this.data.phone.length > 0) || false;
    },
    computedHasToValidateRequired() {
      return this.interaction.hasToValidateRequired || false;
    },
    computedValidPhone() {
      return this.interaction.validPhone || false; 
    },
    computedTranslations() {
      return {
        phoneNumberLabel: this.$t('components.auth.steps.phone.placeholder'),
        example: ''
      };
    },
  },
  methods: {
    handlePhoneUpdated(payload) {
      this.interaction.validPhone = payload.isValid;
      this.data.formattedPhone = `+${payload.countryCallingCode} ${payload.nationalNumber}`;
    },
    submit() {
      this.interaction.hasToValidateRequired = true;

      this.$v.$touch();
      if (!this.$v.$invalid && this.interaction.validPhone) {
        this.$emit('submit', { phone: this.data.formattedPhone });
      } 
    },
    onKeyUp(key) {
      if (key.code === 'Enter' || key.code === 'NumpadEnter') {
        this.submit();
      }
    }
  },
  mounted() {
    this.$refs.phoneRef.$children[1].$el.children[0].focus();
  },
  watch: {
    'data.phone': function() {
      this.interaction.hasToValidateRequired = this.false;
    },
  },
}
</script>