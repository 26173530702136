<template lang="html">
  <div></div>
</template>

<script>
export default {
  name: 'CrispScript',
  props: {
    user: {
      type: Object,
      required: true,
    }
  },
  methods: {
    startChat() {
      // if (!this.$crisp.is("session:ongoing")) {
        // this.$crisp.push(["do", "session:reset"]);
        // this.$crisp.push(["do", "message:show", ["text", "Oiii, você precisa de ajuda ou tem dúvida em algo?"]]);
      // }
    }
  },
  mounted() {
    setTimeout(() => {
      //this.startChat()
    }, 20000)
  },
};
</script>
