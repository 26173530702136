const state = {
  loading: false,
};

const getters = {
  getLoading: $state => $state.loading,
  hasLoading: $state => $state.loading,
};

const actions = {
  initLoading({ commit }) {
    commit('setLoading', true);
  },

  removeLoading({ commit }) {
    commit('setLoading', false);
  },
};

const mutations = {
  setLoading($state, payload) {
    const stateCopy = $state;
    stateCopy.loading = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
