<template>
    <div class="row">
      <div class="col-md-6">
        <div class="input-group">
          <div class="radio-container mb-3">
            <p-radio class="p-default p-round text-white w-100" color="primary-o" v-model="data.salesRange" value="[0]">
              {{ $t('components.auth.steps.salesRange.options.none') }}
            </p-radio>
          </div>

          <div class="radio-container mb-3">
            <p-radio class="p-default p-round text-white w-100" color="primary-o" v-model="data.salesRange" value="[0,10]">
              {{ $t('components.auth.steps.salesRange.options.0_10') }}
            </p-radio>
          </div>

          <div class="radio-container mb-3">
            <p-radio class="p-default p-round text-white w-100" color="primary-o" v-model="data.salesRange" value="[10,30]">
              {{ $t('components.auth.steps.salesRange.options.10_30') }}
            </p-radio>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="input-group">
          <div class="radio-container mb-3">
            <p-radio class="p-default p-round text-white w-100" color="primary-o" v-model="data.salesRange" value="[30,60]">
              {{ $t('components.auth.steps.salesRange.options.30_60') }}
            </p-radio>
          </div>

          <div class="radio-container mb-3">
            <p-radio class="p-default p-round text-white w-100" color="primary-o" v-model="data.salesRange" value="[60,100]">
              {{ $t('components.auth.steps.salesRange.options.60_100') }}
            </p-radio>
          </div>

          <div class="radio-container mb-3">
            <p-radio class="p-default p-round text-white w-100" color="primary-o" v-model="data.salesRange" value="[100]">
              {{ $t('components.auth.steps.salesRange.options.100_plus') }}
            </p-radio>
          </div>
        </div>
      </div>
  
      <p class="text-danger" v-if="!$v.data.salesRange.required && computedHasToValidateRequired">{{ $t('components.auth.steps.salesRange.validations.required') }}</p>
      
      <button id="continue-button" @click="submit()" :disabled="computedIsLoading" class="btn w-100 btn-primary mt-4">
        <template v-if="computedIsLoading">
          <i class="fe fe-loader"></i> {{ $t('components.auth.steps.salesRange.loading') }}
        </template>
        <template v-else>
          {{ $t('components.auth.steps.salesRange.continue') }}
        </template>
      </button>
    </div>
  </template>
  
<script>
import { required } from 'vuelidate/lib/validators'




export default {
  name: 'SalesRangeStep',
  props: {
  isLoading: {
    type: Boolean,
    default: false,
  },
},
  data() {
    return {
      data: {
        salesRange: this.salesRange || ''
      },
      interaction: {
        hasToValidateRequired: false,
      }
    };
  },
  validations: {
    data: {
      salesRange: {
        required,
      }
    }
  },
  computed: {
    computedHasSelectedSalesRange() {
      //scroll to continue button
      const continueButton = document.getElementById('continue-button');
      continueButton.scrollIntoView({behavior: 'smooth'});
      //select the sales range
      return (this.data.salesRange && this.data.salesRange.length > 0) || false;
    },
    computedHasToValidateRequired() {
      return this.interaction.hasToValidateRequired || false;
    },
    computedIsLoading() {
      return this.isLoading || false;
    },
  },
  methods: {
    submit() {
      this.interaction.hasToValidateRequired = true;

      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.$emit('submit', { sales_range: JSON.parse(this.data.salesRange) })
      }        
    },
  },
  watch: {
    'data.salesRange': function() {
      this.interaction.hasToValidateRequired = this.computedHasSelectedSalesRange;
    },
  },
}
</script>

<style lang="css" scoped>
  .input-gorup {
    user-select: none !important;
  }
</style>
