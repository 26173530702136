<template>
  <modal classes="modal-default" name="modal-desktop" height="400px">
    <div class="modal-body pt-10 mt-3">
      <img align="right" @click="hideModalDesktop" src="/img/close-sign.svg" style="cursor: pointer;">
      <img class="pb-2" src="/img/emoji-surprise.svg" style="display: block; margin-left: auto; margin-right: auto;">
      <div class="modal-sub-header pb-2">{{ $t('components.plans.modals.monthlyDesktop.title') }}</div>
      <div class="modal-text pb-4">{{ $t(`components.plans.modals.monthlyDesktop.description.${occurrence}`) }}</div>
      <div class="modal-container">
        <div style="display: grid;">
          <span class="text-span-4 pb-1" style="font-size: 14px;text-align: center; font-weight: 300 !important;">{{ $t('components.plans.modals.monthlyDesktop.class') }}</span>
          <img style="width: 203px; height: 104px;" src="/gifs/adminer-class.gif">
        </div>
        <div style="display: grid;">
          <span class="text-span-4 pb-1" style="font-size: 14px;text-align: center; font-weight: 300 !important;">{{ occurrence == 'quarterly' ? '20' : '35' }}{{ $t('components.plans.modals.monthlyDesktop.discount') }}</span>
          <img style="width: 203px; height: 104px;" src="/gifs/gif-desconto.gif">
        </div>
      </div>
      <div class="modal-footer-custom pt-4">
        <button @click="successDesktop" class="button-primary is-light is-small w-80" style="font-size: 18px;padding-bottom: 0.75rem;padding-top: 0.75rem; border: unset;" >{{ $t(`components.plans.modals.monthlyDesktop.cta.${occurrence}`) }}</button>
      </div>
    </div>
  </modal>
</template>

<script>
export default {
  name: 'MonthlyDesktop',
  props: {
    occurrence: {
      type: String,
      required: true
    }
  },
  methods: {
    hideModalDesktop() {
      this.$modal.hide('modal-desktop');
    },
    successDesktop() {
      this.$emit('success');
    }
  }
}
</script>

<style>
.modal-default{
  width: 580px !important;
  height: 513px !important;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  background-color: #101010 !important;
  border-radius: 8px !important;
  border: 1px solid #2D2D2D;
}

.modal-body{
  display: block;
}

.modal-sub-header{
  justify-content: center !important;
  text-align: center !important;
  color:#ffffff;
  width: 70%;
  margin: auto;  
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 110%;
}

.modal-text{
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  display: flex;
  margin: auto !important;
  align-items: center !important;
  text-align: center;
  color: #B5B3B3;
  padding-top: 5px;
  width: 450px !important;
}

.modal-container{
  display: grid;
  grid-template-columns:repeat(2, auto);
  width: 450px !important;
  justify-content: space-between !important;
  margin: auto !important;
}

.text-span-4{
  color: #ffffff;
}

.modal-footer-custom{
  padding-top: 5px !important;
  text-align: center;
  margin: auto;
}

.vm--overlay {
  background: rgba(0,0,0,.8) !important;
  opacity: 1 !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

</style>