<template>
  <div class="background-container h-75 d-flex justify-content-center align-items-center">
    <div class="d-flex comparison-container justify-content-center">
      <div class="cards-comparison card-premium d-flex">
        <button class="premium-button">
          {{ $t('components.planComparison.premium.header') }}
        </button> 
        <img src="/img/adminer-logo.svg" style="margin-bottom: 1rem;">
        <h2 class="comparison-card-title">{{ $t('components.planComparison.premium.title') }}</h2>
        <div class="container-use-info">
          <img src="/img/steps-premium.svg">
          <p class="m-0 user-info-text" v-html="$t('components.planComparison.premium.useInfo')"></p>
        </div>
        <div class="d-flex background-shade">
          <a  @click="premiumMining()" class="premium-mining-button">
            {{ $t('components.planComparison.premium.cta') }}
          </a>
          <div class="details-container-premium">
            <div class="video-container d-flex">
              <video style="border-radius: 16px;" width="350" height="200" controls autoplay loop>
                <source src="/videos/premium-advantages.mp4" type="video/mp4">
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
          <div class="d-flex testimonials-container">
            <img style="width: 178px; z-index: 1;" src="/img/testimonials.png">
            <h5 style="font-weight: 400 !important;">{{ $t('components.planComparison.premium.footer') }}</h5>
          </div>
        </div>
      </div>
      <div class="cards-comparison card-free d-flex flex-direction-column">
        <button class="free-button"> 
          Free
        </button> 
        <img src="/img/extractor-logo.svg" style="margin-bottom: 1rem;">
        <h2 class="comparison-card-title" v-html="$t('components.planComparison.free.title')"></h2>
        <div class="container-use-info">
          <img src="/img/steps-free.svg">
          <p class="m-0 user-info-text" v-html="$t('components.planComparison.free.useInfo')"></p>
        </div>
        <div class="d-flex background-shade">
          <a  @click="freeMining()" class="free-mining-button" target="_blank">
            {{ $t('components.planComparison.free.cta') }}
          </a>
          <div class="details-container">
            <div class="comparison-details d-flex">
              <img class="free-check" src="/img/free-check-sign.svg">
              <h5 class="comparison-details-text">{{ $t('components.planComparison.free.benefits.one') }}</h5>
            </div>
            <div class="comparison-details d-flex">
              <img class="free-x-sign" src="/img/free-x-sign.svg">
              <h5 class="comparison-details-text">{{ $t('components.planComparison.free.benefits.two') }}</h5>
            </div>
            <div class="comparison-details d-flex">
              <img class="free-x-sign" src="/img/free-x-sign.svg">
              <h5 class="comparison-details-text">{{ $t('components.planComparison.free.benefits.three') }}</h5>
            </div>
            <div class="comparison-details d-flex">
              <img class="free-x-sign" src="/img/free-x-sign.svg">
              <h5 class="comparison-details-text">{{ $t('components.planComparison.free.benefits.four') }}</h5>
            </div>
          </div>
        </div>
      </div>
      
      
    </div>
  </div>
</template>
  
<script>
  export default {
    name: 'PlanComparison',
    components: {
    },
    data() {
      return {
        user: null,
      };
    },
    computed: {
      getUser() {
        return this.$store.getters.currentUser || {};
      },
      extensionUrl() {
        return `${process.env.VUE_APP_API_URL}?active-extension=true`
      },
    },
    mounted() {
      this.$store.dispatch('fetchUser').then((user) => {
        if (!user.id) {
          this.$router.push({ name: 'UserRegister' });
        }
      });
      
    },
    methods: { 
      freeMining() {
        this.$mixpanel.track('free_mining_extension_trigger', {});
        this.$cookie.set("user_token", this.$store.getters.getToken, {
          domain: 'app.adminer.pro',
          path: '/'
        });
        this.$cookie.set("user_id", this.$store.getters.currentUser.id, {
          domain: 'app.adminer.pro',
          path: '/'
        });
        this.$cookie.set("user_email", this.$store.getters.getEmail, {
          domain: 'app.adminer.pro',
          path: '/'
        });
        this.$cookie.set("user_subscriber", null, {
          domain: 'app.adminer.pro',
          path: '/'
        });
        this.$cookie.set("user_plan", null, {
          domain: 'app.adminer.pro',
          path: '/'
        });
        this.$cookie.set("forever_premium", null, {
          domain: 'app.adminer.pro',
          path: '/'
        });
        window.open(`${process.env.VUE_APP_API_URL}/users/session_authorize/new?email=${this.$store.getters.getEmail}&token=${this.$store.getters.getToken}&path=extension_users_path(:me)`, '_blank');
      },
      premiumMining() {
        this.$mixpanel.track('premium_mining_extension_trigger', {});
        this.$router.push({ name: 'RecomendedPlan' })
      },
    }
  };
</script>

<style>
  .user-info-text{
    color: #B5B3B3;
  }
  .container-use-info{
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: center;
  }
  .cards-comparison{
    height: 30rem;
    width: 25rem;
    color: #fff;
  }
  .comparison-container{
    column-gap: 5rem;
  }
  .card-premium{
    position: relative;
    flex-direction: column;
    align-items: center; 
  }
  .card-premium::before {
    border-radius: 24px;
    border-image: linear-gradient(45deg,#f29932,#e75341) 1;
    background: linear-gradient(45deg,#f29932,#e75341);
    border: 2px solid;
    content: "";
    position: absolute;
    inset: 0;
    -webkit-mask: 
      linear-gradient(#fff 0 0) content-box, 
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    z-index: 1;
  }
  .card-free{
    position: relative;
    border: 2px solid;
    border-radius: 24px;
    border-color: #2D2D2D;
    flex-direction: column;
    align-items: center;
  }
  .free-button{
    width: fit-content;
    height: fit-content;
    background-color: #2D2D2D;
    color: #fff;
    border-radius: 8px;
    border: unset;
    top: -15px;
    position: relative;
  }
  .premium-button{
    z-index: 2;
    width: fit-content;
    height: fit-content;
    background: linear-gradient(180deg, #F29932 0%, #E75341 100%);
    color: #fff;
    border-radius: 8px;
    border: unset;
    top: -15px;
    position: relative;
  }
  .comparison-card-title{
    text-align:center;
    line-height: 130%;
    font-size: 1.375rem;
  }
  .background-shade{
    position: absolute;
    bottom: 0px;
    flex-direction: column;
    background-color: #101010;
    width: 100%;
    height: 17.175rem;
    border-radius: 0px 0px 21px 21px;
    margin-top: .5rem;
  }
  .free-mining-button{
    background-color: #2D2D2D;
    border: unset;
    margin-top: 1rem;
    color: #fff;
    border-radius: 8px;
    padding: 14px 28px 14px 28px; 
    margin-bottom: 1rem;
    cursor: pointer;
    align-self: center;
  }
  .free-mining-button:hover {
    color: inherit;
    text-decoration: none;
  }
  .premium-mining-button{
    background: linear-gradient(270deg, #F29932 0%, #E75341 100%);
    border: unset;
    margin-top: 1rem;
    color: #fff;
    border-radius: 8px;
    padding: 14px 28px 14px 28px; 
    margin-bottom: 1rem;
    z-index: 1;
    cursor: pointer;
    align-self: center;
  }
  .premium-mining-button:hover {
    color: inherit;
    text-decoration: none;
  }
  .comparison-details{
    align-items: baseline;
  }
  .comparison-details-text{
    line-height: 1.4 !important;
    font-weight: 400 !important;
  }
  .free-check{
    margin-right: .7rem;
  }
  .free-x-sign{
    margin-right: .7rem;
  }
  .premium-check{
    margin-right: .7rem;
  }
  .details-container{
    display: flex;
    flex-direction: column;
    width: 60%;
    align-self: center;
  }
  .details-container-premium{
    display: flex;
    flex-direction: column;
    width: 100%;
    align-self: center;
  }
  .testimonials-container{
    top: 25px;
    flex-direction: column;
    align-self: center;
    width: auto;
    height: 38px;
    position: relative;
  }
  .video-container{
    justify-content: center;
  }
</style>