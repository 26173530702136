<template>
  <div>
    <div class="input-group justify-content-around">
      <div class="interest-radio-container mb-3">
        <p-radio class="p-default p-round text-white w-100 radio-text" color="primary-o" v-model="data.interest" value="dropshipping">
          {{ $t('components.auth.steps.interest.options.drop') }}
        </p-radio>
      </div>

      <div class="interest-radio-container mb-3">
        <p-radio class="p-default p-round text-white w-100 radio-text" color="primary-o" v-model="data.interest" value="ecommerce">
          {{ $t('components.auth.steps.interest.options.ecom') }}
        </p-radio>
      </div>

      <div class="interest-radio-container mb-3">
        <p-radio class="p-default p-round text-white w-100 radio-text" color="primary-o" v-model="data.interest" value="info">
          {{ $t('components.auth.steps.interest.options.info') }}
        </p-radio>
      </div>

      <div class="interest-radio-container mb-3">
        <p-radio class="p-default p-round text-white w-100 radio-text" color="primary-o" v-model="data.interest" value="plr">
          {{ $t('components.auth.steps.interest.options.plr') }}
        </p-radio>
      </div>

      <div class="interest-radio-container mb-3">
        <p-radio class="p-default p-round text-white w-100 radio-text" color="primary-o" v-model="data.interest" value="affiliates">
          {{ $t('components.auth.steps.interest.options.affiliate') }}
        </p-radio>
      </div>

      <div class="interest-radio-container mb-3">
        <p-radio class="p-default p-round text-white w-100 radio-text" color="primary-o" v-model="data.interest" value="igaming">
          {{ $t('components.auth.steps.interest.options.igaming') }}
        </p-radio>
      </div>

      <div class="interest-radio-container mb-3">
        <p-radio class="p-default p-round text-white w-100 radio-text" color="primary-o" v-model="data.interest" value="agency">
          {{ $t('components.auth.steps.interest.options.agency') }}
        </p-radio>
      </div>

      <div class="interest-radio-container mb-3">
        <p-radio class="p-default p-round text-white w-100 radio-text" color="primary-o" v-model="data.interest" value="cpa">
          {{ $t('components.auth.steps.interest.options.cpa') }}
        </p-radio>
      </div>

      <div class="interest-radio-container mb-3">
        <p-radio class="p-default p-round text-white w-100 radio-text" color="primary-o" v-model="data.interest" value="traffic">
          {{ $t('components.auth.steps.interest.options.traffic') }}
        </p-radio>
      </div>

      <div class="interest-radio-container mb-3">
        <p-radio class="p-default p-round text-white w-100 radio-text" color="primary-o" v-model="data.interest" value="seo">
          {{ $t('components.auth.steps.interest.options.seo') }}
        </p-radio>
      </div>

      <div class="other-interest-radio-container mb-3 d-flex align-items-center">
        <p-radio class="p-default p-round text-white radio-text" color="primary-o" v-model="data.interest" value="other">
          {{ $t('components.auth.steps.interest.options.other') }}
        </p-radio>
        <input class="w-100" v-model="data.otherInterest" id="other-interest-input">
      </div>

    </div>

    <p class="text-danger" style="padding-left: 2%;" v-if="$v.$invalid && computedHasToValidateRequired">{{ $t(data.requiredErrorMessage) }}</p>
    <div class="d-flex" style="width: 100%; justify-content: center;">
      <button @click="submit()" class="btn btn-primary mt-4" style="width: 98%;">
        {{ $t('components.auth.steps.interest.continue') }}
      </button>
    </div>
  </div>
</template>
  
<script>
import { required, requiredIf } from 'vuelidate/lib/validators';

export default {
  name: 'InterestStep',
  data() {
    return {
      data: {
        interest: this.interest || '',
        otherInterest: '',
        requiredErrorMessage: 'components.auth.steps.interest.validations.required'
      },
      interaction: {
        hasToValidateRequired: false,
        interestRadioContainerWidth: this.getInterestRadioContainerWidth(),
        otherInterestRadioContainerWidth: this.getOtherInterestRadioContainerWidth()
      },
    };
  },
  validations: {
    data: {
      interest: {
        required,
      },
      otherInterest: {
        requiredIf: requiredIf(function(data) {
          return data.interest === 'other';
        })
      }
    }
  },
  computed: {
    computedHasSelectedInterest() {
      if (this.data.interest && this.data.interest.length > 0) {
        if (this.data.interest === 'other') {
          return (this.data.otherInterest.length > 0);
        }

        return true;
      }

      return false;
    },
    computedHasToValidateRequired() {
      return this.interaction.hasToValidateRequired || false;
    },
    computedInterest() {
      if (this.data.interest === 'other') {
        return this.data.otherInterest;
      }

      return this.data.interest;
    },
    computedOtherInterest() {
      if (this.data.interest === 'other') {
        return this.data.otherInterest;
      }

      return null;
    }
  },
  methods: {
    submit() {
      this.interaction.hasToValidateRequired = true;

      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.$emit('submit', { interest: this.computedInterest, otherInterest: this.computedOtherInterest })
      }
    },
    getInterestRadioContainerWidth() {
      const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

      if (isMobile) {
        return '100%';
      }

      return '48%';
    },
    getOtherInterestRadioContainerWidth() {
      const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

      if (isMobile) {
        return '100%';
      }

      return '98%';
    }
  },
  watch: {
    'data.interest': function() {
      this.interaction.hasToValidateRequired = this.computedHasSelectedInterest;
      if (this.data.interest === 'other') {
        this.data.requiredErrorMessage = 'components.auth.steps.interest.validations.otherRequired';
      } else {
        this.data.requiredErrorMessage = 'components.auth.steps.interest.validations.required';
      }
    },
    'data.otherInterest': function() {
      this.interaction.hasToValidateRequired = this.computedHasSelectedInterest;
    }
  },
}
</script>

<style lang="scss" scoped>
.radio-text {
  font-size: 16px;
}

.interest-radio-container {
  padding: 16px;
  border: 1px solid #2d2d2d;
  border-radius: 8px !important;
  width: v-bind("interaction.interestRadioContainerWidth");
}

.other-interest-radio-container {
  padding: 16px;
  border: 1px solid #2d2d2d;
  border-radius: 8px !important;
  width: v-bind("interaction.otherInterestRadioContainerWidth");
}

#other-interest-input {
  background: none;
  color: #d9d9d9;
  border: none;
  border-bottom: 1px #2D2D2D solid;
}
</style>