import Users from '@/api/resources/users';

const state = {
  user: {
    id: null,
    name: null,
    email: null,
    phone: null,
    interest: null,
    sales_range: null,
    silver_config: null,
    gold_config: null,
    diamond_config: null,
    stripe_public_key: null,
    affiliate_name: null,
    testing_type: null
  },
};

const getters = {
  currentUser: () => (state.user),
};

const actions = {
  register({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      Users.register(payload).then((response) => {
        commit('setUser', response);
        dispatch('createSession', response);
        return resolve(response);
      })
        .catch((error) => {
          reject(error);
        });
    });
  },
  googleRegister({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      Users.googleRegister(payload).then((response) => {
        commit('setUser', response);
        dispatch('createSession', response);
        return resolve(response);
      })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchUser({ commit, dispatch }) {
    return new Promise((resolve) => {
      Users.show().then((response) => {
        commit('setUser', response);
        dispatch('createSession', response);
        return resolve(response);
      });
    });
  },
  updateUser({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      Users.update(payload).then((response) => {
        commit('setUser', response);
        dispatch('createSession', response);
        return resolve(response);
      })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

const mutations = {
  setUser($state, payload) {
    const stateCopy = $state;
    stateCopy.user = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
