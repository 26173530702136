<template>
    <modal classes="otherplans-modal-mobile" name="otherplans-mobile">
      <div v-show="quarterlyContent" class="upper-tag" style="font-size: .8rem; padding-bottom: .2rem; padding-top: .2rem;"> 
        <img src="/img/cupom-icon.svg">
        {{ $t('components.plans.modals.otherPlansMobile.card.header.discount.quarterly') }}
      </div>
      <div v-show="monthlyContent && affiliate_content && user.gold_config.monthly.coupon" class="off_wrap bg-white upper-tag-affiliate" style="font-size: 0.8rem; display: flex;">
        <div class="text-align-center monthly-text">
          {{ $t('components.plans.modals.otherPlansMobile.card.header.discount.affiliate', { discount: computedDiscountText, affiliateName: computedHasAffiliate }) }}
        </div>
      </div>
      <div class="container-mobile-custom">
        <img align="right" @click="hideModalMobile" src="/img/close-sign.svg" style="cursor: pointer;">
        <div class="toggle-wrapper table mb-0">
          <div class="toggle-bg table">
            <div @click="changePriceFromMonthly(), showQuarterlyContent(), selectedPlan = 'quarterly'" class="toggle-text monthly">
            <div v-if="computedSelectedPlan == 'quarterly'" class="toggle-btn table" style="width: 114px !important;"></div>
              {{ $t('components.plans.modals.otherPlansMobile.card.header.occurrence.quarterly') }}
            </div>
            <div @click="changePriceFromQuarterly(), hideQuarterlyContent(), selectedPlan = 'monthly'" class="toggle-text monthly">
            <div v-if="computedSelectedPlan == 'monthly'" class="toggle-btn table"></div>
              {{ $t('components.plans.modals.otherPlansMobile.card.header.occurrence.monthly') }}
            </div>
          </div>
        </div>
      </div>
      
      <div class="plans-descriptions-mobile">
        <div class="plans-label-mobile">
          Gold
          <div v-show="quarterlyContent || this.computedHasDiscount" class="bad-price-mobile d-flex">
            <p v-show="quarterlyContent || this.computedHasDiscount" style="align-self: center; font-size: .7rem; margin-bottom: 0rem;">{{ computedFormattedCurrency }}</p> 
            <p v-show="quarterlyContent || this.computedHasDiscount" style="padding-left: .2rem; margin-bottom: 0rem;">{{ parseInt(user.gold_config.monthly.amount) }}</p>
          </div>
          <div v-show="quarterlyContent || this.computedHasDiscount" class="cross-line"></div>
            <div class="good-price d-flex">
              <p style="align-self: center; font-size: .4rem; margin-bottom: 0rem;">{{ computedFormattedCurrency }}</p> 
              <p v-show="selectedPlan == 'monthly' && this.computedPlanGold.coupon?.percent_off" style="padding-left: .2rem; margin-bottom: 0rem; font-size: 1.5rem;"> {{ parseInt(user.gold_config.monthly.amount - (user.gold_config.monthly.amount * (user.gold_config.monthly.coupon?.percent_off / 100))) }} </p> 
              <p v-show="selectedPlan == 'monthly' && this.computedPlanGold.coupon?.amount_off" style="padding-left: .2rem; margin-bottom: 0rem; font-size: 1.5rem;"> {{ parseInt(user.gold_config.monthly.amount - (user.gold_config.monthly.coupon?.amount_off)) }} </p> 
              <p v-show="selectedPlan == 'monthly' && this.computedPlanGold.coupon == null" style="padding-left: .2rem; margin-bottom: 0rem; font-size: 1.5rem;"> {{ bigPriceGold }} </p> 
              <p v-show="selectedPlan == 'quarterly'" style="padding-left: .2rem; margin-bottom: 0rem; font-size: 1.5rem;"> {{ bigPriceGold }} </p> 
              <div style="align-self: center;">
              </div>
            </div>
            <p v-show="quarterlyContent" class="price-text" style="font-size: .6rem;">{{ $t('components.plans.modals.otherPlansMobile.card.header.price.perMonth') }}</p> 
            <p v-show="quarterlyContent" class="price-text" style="font-size: .6rem;">{{ $t('components.plans.modals.otherPlansMobile.card.header.price.quarterlyCharged') }}</p> 
        </div>
        <div class="plans-label-mobile">
          Diamond
          <div v-show="quarterlyContent" class="bad-price-mobile d-flex">
            <p v-show="quarterlyContent" style="align-self: center; font-size: .7rem; margin-bottom: 0rem;">{{ computedFormattedCurrency }}</p> 
            <p v-show="quarterlyContent" style="padding-left: .2rem; margin-bottom: 0rem;">{{ parseInt(user.diamond_config.monthly.amount) }}</p>
          </div>
          <div v-show="quarterlyContent" class="cross-line"></div>
            <div class="good-price d-flex">
              <p style="align-self: center; font-size: .4rem; margin-bottom: 0rem;">{{ computedFormattedCurrency }}</p> 
              <p :v-html="bigPriceDiamond" style="padding-left: .2rem; margin-bottom: 0rem; font-size: 1.5rem;">{{ bigPriceDiamond }}</p> 
              <div style="align-self: center;">
              </div>
            </div>
            <p v-show="quarterlyContent" class="price-text" style="font-size: .6rem;">{{ $t('components.plans.modals.otherPlansMobile.card.header.price.perMonth') }}</p> 
            <p v-show="quarterlyContent" class="price-text" style="font-size: .6rem;">{{ $t('components.plans.modals.otherPlansMobile.card.header.price.quarterlyCharged') }}</p> 
        </div>
      </div>
      <div class="bg-white text-center" v-show="this.computedHasTrial && selectedPlan == 'monthly'" style="margin-left: .8rem; margin-bottom: .8rem; padding: 2px 2px;border-radius:2px;transform: rotate(-1deg);width: 50% !important;">
        <p class="m-0" style="font-size: .5rem; font-weight: bold; background: linear-gradient(180deg, #F29932 0%, #E75341 100%);-webkit-background-clip: text;-webkit-text-fill-color: transparent;background-clip: text;text-fill-color: transparent;">
          {{ $t('components.plans.modals.otherPlansMobile.card.header.cta.trial',  { trialDays: computedTrial }) }}
        </p>
      </div>
      <div class="modal-content-mobile">
       <div class="plan-benefit">{{ $t('components.plans.modals.otherPlansMobile.card.body.benefits.mining.title') }}</div>
        <div class="benefits-signs-mobile">
          <div class="pricing-table-row" style="padding-top: 0rem; width: 30%; border-bottom: 2px solid #2d2d2d;">
            <div class="pricing-table-row-title">
              <div class="pricing-card-row mobile-center">
              </div>
            </div>
            <div class="pricing-card-row center">
              <div class="pricing-icon table"></div>
            </div>
          </div>
          <div class="pricing-table-row" style="padding-top: 0rem; width: 30%; border-bottom: 2px solid #2d2d2d;">
            <div class="pricing-table-row-title">
              <div class="pricing-card-row mobile-center">
              </div>
            </div>
            <div class="pricing-card-row center">
              <div class="pricing-icon table"></div>
            </div>
          </div>
        </div>
       <div class="plan-benefit">{{ $t('components.plans.modals.otherPlansMobile.card.body.benefits.premiumLib.title') }}</div>
       <div class="benefits-signs-mobile">
          <div class="pricing-table-row" style="padding-top: 0rem; width: 30%; border-bottom: 2px solid #2d2d2d;">
            <div class="pricing-table-row-title">
              <div class="pricing-card-row mobile-center">
              </div>
            </div>
            <div class="pricing-card-row center">
              <div class="text-mobile-modals">{{ $t('components.plans.modals.otherPlansMobile.card.body.benefits.premiumLib.value', { franchise: 20 }) }}</div>
            </div>
          </div>
          <div class="pricing-table-row" style="padding-top: 0rem; width: 30%; border-bottom: 2px solid #2d2d2d;">
            <div class="pricing-table-row-title">
              <div class="pricing-card-row mobile-center">
              </div>
            </div>
            <div class="pricing-card-row center">
              <div class="text-mobile-modals">{{ $t('components.plans.modals.otherPlansMobile.card.body.benefits.premiumLib.value', { franchise: 60 }) }}</div>
            </div>
          </div>
        </div>
       <div class="plan-benefit">{{ $t('components.plans.modals.otherPlansMobile.card.body.benefits.advancedFilters.title') }}</div>
       <div class="benefits-signs-mobile">
          <div class="pricing-table-row" style="padding-top: 0rem; width: 30%; border-bottom: 2px solid #2d2d2d;">
            <div class="pricing-table-row-title">
              <div class="pricing-card-row mobile-center">
              </div>
            </div>
            <div class="pricing-card-row center">
              <div class="pricing-icon table"></div>
            </div>
          </div>
          <div class="pricing-table-row" style="padding-top: 0rem; width: 30%; border-bottom: 2px solid #2d2d2d;">
            <div class="pricing-table-row-title">
              <div class="pricing-card-row mobile-center">
              </div>
            </div>
            <div class="pricing-card-row center">
              <div class="pricing-icon table"></div>
            </div>
          </div>
        </div>
       <div class="plan-benefit">{{ $t('components.plans.modals.otherPlansMobile.card.body.benefits.marketAnalysis.title') }}</div>
       <div class="benefits-signs-mobile">
          <div class="pricing-table-row" style="padding-top: 0rem; width: 30%; border-bottom: 2px solid #2d2d2d;">
            <div class="pricing-table-row-title">
              <div class="pricing-card-row mobile-center">
              </div>
            </div>
            <div class="pricing-card-row center">
              <div class="pricing-icon table"></div>
            </div>
          </div>
          <div class="pricing-table-row" style="padding-top: 0rem; width: 30%; border-bottom: 2px solid #2d2d2d;">
            <div class="pricing-table-row-title">
              <div class="pricing-card-row mobile-center">
              </div>
            </div>
            <div class="pricing-card-row center">
              <div class="pricing-icon table"></div>
            </div>
          </div>
        </div>
       <div class="plan-benefit">{{ $t('components.plans.modals.otherPlansMobile.card.body.benefits.hotProducts.title') }}</div>
       <div class="benefits-signs-mobile">
          <div class="pricing-table-row" style="padding-top: 0rem; width: 30%; border-bottom: 2px solid #2d2d2d;">
            <div class="pricing-table-row-title">
              <div class="pricing-card-row mobile-center">
              </div>
            </div>
            <div class="pricing-card-row center">
              <div class="text-mobile-modals">{{ $t('components.plans.modals.otherPlansMobile.card.body.benefits.hotProducts.goldValue') }}</div>
            </div>
          </div>
          <div class="pricing-table-row" style="padding-top: 0rem; width: 30%; border-bottom: 2px solid #2d2d2d;">
            <div class="pricing-table-row-title">
              <div class="pricing-card-row mobile-center">
              </div>
            </div>
            <div class="pricing-card-row center">
              <div class="text-mobile-modals">{{ $t('components.plans.modals.otherPlansMobile.card.body.benefits.hotProducts.diamondValue') }}</div>
            </div>
          </div>
        </div>
       <div class="plan-benefit">{{ $t('components.plans.modals.otherPlansMobile.card.body.benefits.copy.title') }}</div>
       <div class="benefits-signs-mobile">
          <div class="pricing-table-row" style="padding-top: 0rem; width: 30%; border-bottom: 2px solid #2d2d2d;">
            <div class="pricing-table-row-title">
              <div class="pricing-card-row mobile-center">
              </div>
            </div>
            <div class="pricing-card-row center">
              <div class="pricing-icon table not-included"></div>
            </div>
          </div>
          <div class="pricing-table-row" style="padding-top: 0rem; width: 30%; border-bottom: 2px solid #2d2d2d;">
            <div class="pricing-table-row-title">
              <div class="pricing-card-row mobile-center">
              </div>
            </div>
            <div class="pricing-card-row center">
              <div class="pricing-icon table"></div>
            </div>
          </div>
        </div>
       <div class="plan-benefit">{{ $t('components.plans.modals.otherPlansMobile.card.body.benefits.tiktok.title') }}</div>
       <div class="benefits-signs-mobile">
          <div class="pricing-table-row" style="padding-top: 0rem; width: 30%; border-bottom: 2px solid #2d2d2d;">
            <div class="pricing-table-row-title">
              <div class="pricing-card-row mobile-center">
              </div>
            </div>
            <div class="pricing-card-row center">
              <div class="pricing-icon table not-included"></div>
            </div>
          </div>
          <div class="pricing-table-row" style="padding-top: 0rem; width: 30%; border-bottom: 2px solid #2d2d2d;">
            <div class="pricing-table-row-title">
              <div class="pricing-card-row mobile-center">
              </div>
            </div>
            <div class="pricing-card-row center">
              <div class="pricing-icon table"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex buttons-container-mobile">
        <button v-show="monthlyContent" @click="goToStripeCheckout(user.gold_config.monthly.stripe_id, 'gold', 'monthly')" class="button-secondary button-secondary-custom" style="font-size: .8rem; width: fit-content; background-color: #2d2d2d; padding: 16.4px 19px; font-size: .7rem  ;">
          {{ $t('components.plans.modals.otherPlansMobile.card.footer.cta.gold') }}
        </button>
        <button v-if="computedPlanGold.gateway == 'guru'" v-show="quarterlyContent"  @click="goToGuruCheckout(computedPlanGold.checkout_url, 'gold', 'quarterly')" class="button-secondary button-secondary-custom" style="font-size: .8rem; width: fit-content; background-color: #2d2d2d; padding: 16.4px 19px; font-size: .7rem  ;">
          {{ $t('components.plans.modals.otherPlansMobile.card.footer.cta.gold') }}
        </button>
        <button v-if="computedPlanGold.gateway == 'stripe'" v-show="quarterlyContent"  @click="goToStripeCheckout(user.gold_config.quarterly.stripe_id, 'gold', 'quarterly')" class="button-secondary button-secondary-custom" style="font-size: .8rem; width: fit-content; background-color: #2d2d2d; padding: 16.4px 19px; font-size: .7rem  ;">
          {{ $t('components.plans.modals.otherPlansMobile.card.footer.cta.gold') }}
        </button>

        <button v-show="monthlyContent" @click="goToStripeCheckout(user.diamond_config.monthly.stripe_id, 'diamond', 'monthly')" class="btn-primary" style="font-size: .8rem; width: fit-content; border-radius: 8px !important; padding: 16.4px 19px; font-size: .7rem  ;">
          {{ $t('components.plans.modals.otherPlansMobile.card.footer.cta.diamond') }}
        </button>
        <button v-if="computedPlanDiamond.gateway == 'guru'" v-show="quarterlyContent" @click="goToGuruCheckout(computedPlanDiamond.checkout_url, 'diamond', 'quarterly')" class="btn-primary" style="font-size: .8rem; width: fit-content; border-radius: 8px !important; padding: 16.4px 19px; font-size: .7rem  ;">
          {{ $t('components.plans.modals.otherPlansMobile.card.footer.cta.diamond') }}
        </button>
        <button v-if="computedPlanDiamond.gateway == 'stripe'" v-show="quarterlyContent" @click="goToStripeCheckout(user.diamond_config.quarterly.stripe_id, 'diamond', 'quarterly')" class="btn-primary" style="font-size: .8rem; width: fit-content; border-radius: 8px !important; padding: 16.4px 19px; font-size: .7rem  ;">
          {{ $t('components.plans.modals.otherPlansMobile.card.footer.cta.diamond') }}
        </button>
        </div>
    </modal>
  </template>
  
<script>
  export default {
    name: 'MonthlyMobile',
    props: {
      user: {
        type: Object,
      },
  },
  data(){
    return{
      selectedPlan: 'quarterly'
      ,
      bigPriceDiamond: parseInt(this.user.diamond_config.quarterly.amount)
      ,
      bigPriceGold: parseInt(this.user.gold_config.quarterly.amount)
      ,
      quarterlyContent: true
      ,
      monthlyContent: false
      ,
      affiliate_content: this.user.affiliate_name ? true : false
      ,
    }
  },
  computed: {
    getUser() {
      return this.$store.getters.currentUser || {};
    },
    computedSelectedPlan() {
      return this.selectedPlan || 'quarterly';
    },
    computedPlanGold() {
      if (this.selectedPlan == 'quarterly') {
        return this.user.gold_config.quarterly;
      } else if (this.selectedPlan == 'monthly') {
        return this.user.gold_config.monthly;
      } else if (this.selectedPlan == 'semester') {
        return this.user.gold_config.semester;
      } else {
        return this.user.gold_config.yearly;
      }
    },
    computedPlanDiamond() {
      if (this.selectedPlan == 'quarterly') {
        return this.user.diamond_config.quarterly;
      } else if (this.selectedPlan == 'monthly') {
        return this.user.diamond_config.monthly;
      } else if (this.selectedPlan == 'semester') {
        return this.user.diamond_config.semester;
      } else {
        return this.user.diamond_config.yearly;
      }
    },
    computedHasTrial() {
      return this.user.gold_config.monthly && this.user.gold_config.monthly.trial_days > 0;
    },
    computedTrial() {
      return this.user.gold_config.monthly.trial_days;
    },
    computedDiscountText(){
      let plan;
      if (this.selectedPlan == 'quarterly') {
        plan = this.user.gold_config.quarterly;
      }else {
        plan = this.user.gold_config.monthly;
      }
      if (plan.coupon?.amount_off) {
        return `${this.computedFormattedCurrency}${plan.coupon?.amount_off}`
      } else {
        return `${plan.coupon?.percent_off}%`
      }
    },
    computedHasAffiliate(){
      return this.user.affiliate_name;
    },
    computedHasDiscount() {
      if (this.selectedPlan == 'quarterly') {
        return this.user.gold_config.quarterly.coupon;
      } else {
        return this.user.gold_config.monthly.coupon;
      }
    },
    computedFormattedCurrency() {
      if (this.user.currency == 'BRL') {
        return 'R$';
      } else if (this.user.currency == 'EUR') {
        return '€'
      } else if (this.user.currency == 'USD') {
        return 'US$'
      } else {
        return 'R$'
      }
    },
  },
  methods: {
    changePriceFromMonthly(){
      this.bigPriceDiamond = parseInt(this.user.diamond_config.quarterly.amount);
      this.bigPriceGold = parseInt(this.user.gold_config.quarterly.amount);
    },
    changePriceFromQuarterly(){
      this.bigPriceDiamond = parseInt(this.user.diamond_config.monthly.amount);
      this.bigPriceGold = parseInt(this.user.gold_config.monthly.amount);
    },
    showQuarterlyContent(){
      this.quarterlyContent = true;
      this.monthlyContent = false;
    },
    hideQuarterlyContent(){
      this.quarterlyContent = false;
      this.monthlyContent = true;
    },
    showModalMobile() {
      this.$modal.show('otherplans-mobile');
    },
    hideModalMobile() {
      this.$modal.hide('otherplans-mobile');
    },
    successMobile() {
      this.$emit('success');
    },
    goToStripeCheckout(stripe_id, plan_kind, occurrence) {
      let successUrl = `${process.env.VUE_APP_API_URL}/users/me/subscriptions/new?session_id={CHECKOUT_SESSION_ID}`
      let cancelUrl = `${process.env.VUE_APP_APP_URL}/plano-recomendado`

      const data = {
        mode: 'subscription',
        price_id: stripe_id,
        cancel_url: cancelUrl,
        success_url: `${process.env.VUE_APP_API_URL}/users/session_authorize/new?email=${this.user.email}&token=${this.user.token}&redirect=${successUrl}`,
      };

      this.$mixpanel.track('other_plans_subscribe_button_clicked', { 
        plan_kind: plan_kind,
        occurrence: occurrence,
      });
      this.isLoading = true;
      this.$store.dispatch('createCheckout', data);
    },
    goToGuruCheckout(checkout_url, plan_kind, occurrence) {
      this.$mixpanel.track('other_plans_subscribe_button_clicked', { 
        plan_kind: plan_kind,
        occurrence: occurrence,
      });
      window.location.href = `${checkout_url}?email=${this.user.email}&name=${this.user.name}`;
    },
  }
  }
</script>

  
<style>
  .bad-price-mobile{
    justify-content: flex-start;
    color: #B5B3B3;
  }
  .buttons-container-mobile{
    padding-left: 1.6rem;
    justify-content: space-between;
    margin-top: 1rem;
    margin-right: 1.2rem;
    padding-bottom: 10rem;
  }
  .benefits-signs-mobile{
    display: flex;
    justify-content: space-around;
  }
  .plan-benefit{
    font-weight: bold;
    padding-top: .8rem;
    display: flex;
    font-size: .8rem;
    color: #ffffff;
    justify-content: center;
  }

  .modal-content-mobile {
    height: 35%;
    overflow: auto !important;
    background: #171717;
    border-radius: 8px;
  }
  
  .plans-label-mobile{
    color: #ffffff;
    font-size: 0.9rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .plans-descriptions-mobile{
    display: flex;
    top: -10px;
    position: relative;
    z-index: 2;
    justify-content: space-around;
  }
  .otherplans-modal-mobile {
    width: 340px !important;
    height: 600px !important;
    background-color: #101010 !important;
    border-radius: 8px !important;
    border: 1px solid #2D2D2D;
    top: 1% !important;
    margin-left: auto !important;
    margin-right: auto !important;
    left: 0 !important;
  }
  
  .vm--overlay {
      background: rgba(0,0,0,.8) !important;
      opacity: 1 !important;
  }
  
  .modal-sub-header-mobile {
    justify-content: center !important;
    text-align: center !important;
    color: #ffffff;
    width: 300px;
    margin: auto;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 100%;
  }
  
  .modal-text-mobile {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 150%;
    display: flex;
    margin: auto !important;
    align-items: center !important;
    text-align: center;
    color: #B5B3B3;
    padding-top: 5px;
    width: auto !important;
  }
  
  .container-mobile-custom {
    display: block;
    margin-left: 20px;
    margin-right: 10px;
    margin-top: 5px;
  }
  
  .text-mobile-modals{
    color: #B5B3B3;
    padding-top: .5rem;
    font-size: .7rem;
  }
</style>