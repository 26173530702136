<template>
  <div class="background-container image">
    <div class="container">
      <div class="card" v-if="isUserLoading == false">
        <div class="card-header mb-3">
          <div v-if="step > 3" class="w-fit-content">
            <span @click="previousStep()" class="text-white"><i class="icon fe fe-chevron-left"></i></span>
          </div>

          <div class="step-img-container">
            <img :src="imgStepFor(1)" class="step-img">
            <img :src="imgStepFor(2)" class="step-img">
            <img :src="imgStepFor(3)" class="step-img">
            <img :src="imgStepFor(4)" class="step-img">
            <img :src="imgStepFor(5)" class="step-img">
            <img :src="imgStepFor(6)" class="step-img">
          </div>
        </div>

        <div class="card-body">
          <div v-if="!isUserFromExtension && !isUserFromTrends" class="header text-center mb-4">
            <img src="https://uploads-ssl.webflow.com/63a1b54c23a6b7879791f4a4/63a353bb022b485690241081_logo_adminer.svg" alt="" class="logo mb-4">
            <h2 class="text-color-light">{{ computedCardTitle }}</h2>
          </div>

          <div v-if="isUserFromExtension" class="header text-center mb-4">
            <img src="/img/extractor-logo.svg" alt="" class="logo-extension mb-4">
            <h2 class="text-color-light">{{ computedCardTitle }}</h2>
          </div>

          <div v-if="isUserFromTrends" class="header text-center mb-4">
            <img src="/img/trends-logo.svg" alt="" class="logo-trends mb-4">
            <h2 class="text-color-light">{{ computedCardTitle }}</h2>
          </div>

          <div class="body">
            <google-login-button v-if="computedStep == 1" @googleBtnCallback="handleCredentialResponse" class="google-btn btn"></google-login-button>
            <div v-if="computedStep == 1" class="separator">
              <div class="line"></div>
              <div class="word"> {{ $t('components.register.or') }} </div>
              <div class="line"></div>
            </div>
            <email-step v-show="computedStep == 1" :token="data.token" @submit="submitEmail" />

            <password-step v-if="computedStep == 2" :isLoading="computedIsLoading" @submit="submitPassword" />

            <name-step v-if="computedStep == 3" :parentName="this.data.name" @submit="submitName" />

            <phone-step v-if="computedStep == 4" @submit="submitPhone" />

            <interest-step v-if="computedStep == 5" @submit="submitInterest" />

            <sales-range-step v-if="computedStep == 6" :isLoading="computedIsLoading" @submit="submitSalesRange" />

            <div v-if="computedStep == 1" style="text-align: center; padding-top: 20px;"><a style="color: #ffffff;" :href="computedLoginLink">{{ $t('components.register.links.already_have_account') }}</a></div>

            <div v-if="computedStep <= 2" class="align-center text-color-dark-3 mt-4">
              <span class="politics" v-html="$t('components.register.links.terms')"></span>
            </div>
          </div>
        </div>
      </div>
      <div class="card" v-if="isUserLoading == true">
        <div class="card-body">
          <google-account-loading />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Tap from "@tapfiliate/tapfiliate-js";

import EmailStep from '@/components/auth/steps/Email.vue';
import PasswordStep from '@/components/auth/steps/Password.vue';
import PhoneStep from '@/components/auth/steps/Phone.vue';
import NameStep from '@/components/auth/steps/Name.vue';
import InterestStep from '@/components/auth/steps/Interest.vue';
import SalesRangeStep from '@/components/auth/steps/SalesRange.vue';
import GoogleAccountLoading from '@/components/GoogleAccountLoading.vue';
import GoogleLoginButton from '@/components/GoogleLoginButton.vue';

export default {
  name: 'UserRegister',
  components: {
    EmailStep,
    PasswordStep,
    PhoneStep,
    NameStep,
    InterestStep,
    SalesRangeStep,
    GoogleAccountLoading,
    GoogleLoginButton,
  },
  data() {
    return {
      data: {
        id: null,
        email: null,
        token: null,
        name: null,
      },
      interaction: {
        isLoading: false,
      },
      step: 1,
    };
  },
  computed: {
    computedCardTitle() {
      if (this.isUserFromExtension) {
        switch (this.step) {
          case 1:
            return this.$t('components.register.steps.extractor.step_1');
          case 2:
            return this.$t('components.register.steps.extractor.step_2');
          case 3:
            return this.$t('components.register.steps.extractor.step_3');
          case 4:
            return this.$t('components.register.steps.extractor.step_4');
          case 5:
            return this.$t('components.register.steps.extractor.step_5');
          case 6:
            return this.$t('components.register.steps.extractor.step_6');
          default:
            return this.$t('components.register.steps.extractor.default');
        }
      } else if (this.isUserFromTrends) {
        switch (this.step) {
          case 1:
            return this.$t('components.register.steps.trends.step_1');
          case 2:
            return this.$t('components.register.steps.trends.step_2');
          case 3:
            return this.$t('components.register.steps.trends.step_3');
          case 4:
            return this.$t('components.register.steps.trends.step_4');
          case 5:
            return this.$t('components.register.steps.trends.step_5');
          case 6:
            return this.$t('components.register.steps.trends.step_6');
          default:
            return this.$t('components.register.steps.trends.default');
        }
      } else {
        switch (this.step) {
          case 1:
            return this.$t('components.register.steps.standard.step_1');
          case 2:
            return this.$t('components.register.steps.standard.step_2');
          case 3:
            return this.$t('components.register.steps.standard.step_3');
          case 4:
            return this.$t('components.register.steps.standard.step_4');
          case 5:
            return this.$t('components.register.steps.standard.step_5');
          case 6:
            return this.$t('components.register.steps.standard.step_6');
          default:
            return this.$t('components.register.steps.standard.default');
        }
      }
    },
    computedStep() {
      return this.step || '1';
    },
    computedIsLoading() {
      return this.interaction.isLoading || false;
    },
    getUser() {
      return this.$store.getters.currentUser || {};
    },
    isUserLoading() {
      return this.$store.getters.getLoading || false;
    },
    isUserFromExtension() {
      return this.$route.query.utm_source == 'extension'
    },
    isUserFromTrends() {
      return this.$route.query.utm_source == 'trends'
    },
    computedLoginLink() {
      if (this.$cookie.get('utm_source') == 'trends') {
        return 'https://trends.adminer.pro'
      } else {
        return 'https://app.adminer.pro/users/sign_in'
      }
    }
  },
  methods: {
    async checkEmailAvailability(token) {
      const res = await fetch(`${process.env.VUE_APP_API_URL}/api/users/google_email_availability?token=${token}`, { method: 'POST' });
      const data = await res.json();
      return data.is_available;
    },
    async handleCredentialResponse(response) {
      this.handleGoogleLoading();
      const { credential } = response;

      const available = await this.checkEmailAvailability(credential);

      if (!available) {

        if (this.$cookie.get("utm_source") == 'trends') {
          window.location.href = `https://trends.adminer.pro`;
        } else {
          window.location.href = `${process.env.VUE_APP_API_URL}/users/google_sign_in?credential=${credential}`;
        }

        return;
      }

      await this.$store.dispatch('googleRegister', {
        token: credential,
        utm_source: this.$cookie.get("utm_source"),
        utm_campaign: this.$cookie.get("utm_campaign"),
        tap_vid: this.$cookie.get("tap_vid"),
        fbp: this.$cookie.get("_fbp"),
        fbc: this.$cookie.get("_fbc"),
        gac: this.$cookie.get("_gac_UA-173435206-1"),
        useragent: window.navigator.userAgent,
      }).then((user) => {
        this.handleGoogleSuccess(user);
      });
    },
    handleGoogleLoading() {
      this.$store.dispatch('initLoading');
    },
    handleGoogleSuccess(user) {
      this.data.id = user.id;
      this.data.name = user.name;
      this.data.email = user.email;
      this.data.token = user.token;

      if (this.$cookie.get("utm_source") == 'trends') {
        this.$gtag.event('conversion', { send_to: 'AW-414791671/ij95CI-Nn_0YEPfv5MUB', event: 'contact' });
      } else {
        this.$gtag.event('conversion', { send_to: 'AW-414791671/LpdYCOrB1PMCEPfv5MUB', event: 'contact' });
      }

      // window.fbq('track', 'Lead');
      window.ttq.track('CompleteRegistration');

      this.$cookie.set("utm_source", null, null);
      this.$cookie.set("utm_campaign", null, null);

      this.step = 4;
      this.$mixpanel.identify(user.id);
      this.$mixpanel.track('register_front_user_registered', { email: user.email, provider: 'google' });
      this.$store.dispatch('removeLoading');
    },
    submitEmail(email) {
      this.data.email = email;
      // this.$crisp.push(["set", "session:segments", [["ESCOLHENDO_PLANO"]]]);
      // this.$crisp.push(["set", "user:email", [email]]);
      this.step = 2;
    },
    async submitPassword(password) {
      this.interaction.isLoading = true;

      if (this.data.token) {
        this.$store.dispatch('updateUser', { password: password }).then((user) => {
          try {
            this.$mixpanel.identify(user.id);
          } catch (e) {
            console.log('Error while identifying user');
          }
        });
      } else {
        const self = this;
        await this.$store.dispatch('register', {
          email: this.data.email,
          password: password,
          utm_source: this.$cookie.get("utm_source"),
          utm_campaign: this.$cookie.get("utm_campaign"),
          tap_vid: this.$cookie.get("tap_vid"),
          fbp: this.$cookie.get("_fbp"),
          fbc: this.$cookie.get("_fbc"),
          gac: this.$cookie.get("_gac_UA-173435206-1"),
          useragent: window.navigator.userAgent,
        }).then((user) => {
          self.data.id = user.id;
          self.data.email = user.email;
          self.data.token = user.token;

          if (this.$cookie.get("utm_source") == 'trends') {
            this.$gtag.event('conversion', { send_to: 'AW-414791671/ij95CI-Nn_0YEPfv5MUB', event: 'contact' });
          } else {
            this.$gtag.event('conversion', { send_to: 'AW-414791671/LpdYCOrB1PMCEPfv5MUB', event: 'contact' });
          }

          // window.fbq('track', 'Lead');
          window.ttq.track('CompleteRegistration');

          this.$cookie.set("utm_source", null, null);
          this.$cookie.set("utm_campaign", null, null);

          this.$mixpanel.identify(user.id);

          // this.$crisp.push(["set", "session:data", [[["user_id", user.id]]]]);
        });
      }

      this.$mixpanel.track('register_front_user_registered', { email: this.data.email, provider: 'email_password' });
      this.interaction.isLoading = false;

      let user = await this.getUser;
      window.location.href = `${process.env.VUE_APP_API_URL}/users/session_authorize/new?redirect=register_step_3&email=${user.email}&token=${user.token}`
    },
    submitName(updateParams) {
      this.updateUser(updateParams);
      this.$mixpanel.track('register_front_submitted_name', { email: this.data.email, name: updateParams.name });
      // this.$crisp.push(["set", "user:nickname", [updateParams.name]]);
      this.step = 4;
    },
    submitPhone(updateParams) {
      this.updateUser(updateParams);
      this.$mixpanel.track('register_front_submitted_phone', { email: this.data.email, phone: updateParams.phone });
      this.step = 5;
    },
    submitInterest(updateParams) {
      this.updateUser(updateParams);
      const otherInterest = updateParams.otherInterest;
      this.$mixpanel.track('register_front_submitted_interest', {
        email: this.data.email,
        interest: otherInterest ? 'other' : updateParams.interest,
        otherInterest: updateParams.otherInterest
      });
      this.step = 6;
    },
    // isUserInfoOrganic(user) {
    //   const hasUtmSource = user.utm_source === null;
    //   const hasUtmCampaign = user.utm_campaign === null;
    //   const hasNoPartnerId = user.affiliate_name === null;
    //   const hasInterestInfo = ['info', 'plr', 'affiliates'].includes(user.interest);
    //   const hasNoSalesRange = user.sales_range[user.sales_range.length - 1] === 0;

    //   return (hasUtmSource && hasUtmCampaign && hasNoPartnerId && hasInterestInfo && hasNoSalesRange);
    // },

    async submitSalesRange(updateParams) {
      this.interaction.isLoading = true;

      this.$store.dispatch('updateUser', updateParams).then(async () => {
        this.$mixpanel.track('register_front_submitted_sales_range', { email: this.data.email, sales_range: updateParams.sales_range });
        this.interaction.isLoading = false;

        // Comentado o chamado do Infoplan. Motivo: Rollback no teste.
        // Lembrar de adicionar o user no parâmetro da promise caso restaure o Infoplan

        // const isUserInfoOrganic = this.isUserInfoOrganic(user);
        // if (isUserInfoOrganic) {
        //   this.$router.push({ name: 'InfoPlan' });
        // } else 
        if (this.isUserFromExtension) {
          this.$router.push({ name: 'PlanLoadingExtension' });
        } else if (this.isUserFromTrends) {
          this.$router.push({ name: 'PlanLoading' });
        } else {
          this.$router.push({ name: 'PlanLoading' });
        }
      });
    },
    previousStep() {
      this.step -= 1;
    },
    imgStepFor(step) {
      if (step == this.step) {
        return 'https://uploads-ssl.webflow.com/63a1b54c23a6b7879791f4a4/63a3579c0d6caf1c8b92d3c1_states_icon-select.svg';
      } else {
        return 'https://uploads-ssl.webflow.com/63a1b54c23a6b7879791f4a4/63a3579bcd144954c9a78031_states_icon-no-select.svg';
      }
    },
    async updateUser(updateParams) {
      this.$store.dispatch('updateUser', updateParams);
    },
  },
  async mounted() {
    let utmSourceParam = this.$route.query.utm_source;
    let utmCampaignParam = this.$route.query.utm_campaign;

    let utmSourceCookie = this.$cookie.get("utm_source");
    let utmCampaignCookie = this.$cookie.get("utm_campaign");

    if (utmSourceCookie && utmSourceCookie != "null") {
      utmSourceParam = utmSourceCookie;
      this.$router.replace({ ...this.$router.currentRoute, query: { ...this.$route.query, utm_source: utmSourceParam } });
    }

    if (utmCampaignCookie && utmCampaignCookie != "null") {
      utmCampaignParam = utmCampaignCookie;
      this.$router.replace({ ...this.$router.currentRoute, query: { ...this.$route.query, utm_campaign: utmCampaignParam } });
    }

    if (utmSourceParam && !utmSourceCookie) {
      this.$cookie.set("utm_source", utmSourceParam, 7);
    }

    if (utmCampaignParam && !utmCampaignCookie && utmSourceParam) {
      this.$cookie.set("utm_campaign", utmCampaignParam, 7);
    }

    Tap.init('18061-e40489');

    let user = null;
    if (this.$route.query.step > -1) {
      this.$store.dispatch('initLoading');
      user = await this.$store.dispatch('fetchUser');
      this.step = this.$route.query.step;
      let query = Object.assign({}, this.$route.query);
      delete query.step;
      this.$router.replace({ query });
    }

    this.$store.dispatch('removeLoading');

    const { token, email } = this.$route.query;
    if (token && email) {
      this.handleGoogleLoading();

      this.$store.dispatch('createSession', { token, email });
      user = await this.$store.dispatch('fetchUser');

      this.handleGoogleSuccess(user);
    }
  },
}
</script>

<style lang="css" scoped>
.fe-eye {
  cursor: pointer;
}

.politics {
  font-size: 0.8rem;
}

.google-btn {
  display: flex;
  justify-content: center;
}

.separator {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
}

.line {
  position: unset;
  flex-grow: 1;
  height: 0.1px;
  background-color: #2D2D2D;
  /* Change the color as per your preference */
}

.word {
  padding: 10px 10px;
  font-weight: bold;
  color: #B5B3B3;
  /* Change the color as per your preference */
}

@media screen and (min-width: 479px) {
  .container {
    padding-top: 7rem !important;
  }
}

@media screen and (max-width: 1900px) and (min-width: 600px) {
  .card {
    margin-top: -5rem !important;
    padding: 0px 40px 20px 40px !important;
  }
}

@media screen and (min-width: 1900px) {
  .card {
    margin-top: -3rem !important;
    padding: 0px 40px 20px 40px !important;
  }
}
</style>
