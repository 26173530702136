<template>
  <div class="background">
    <div class="container">
      <div class="sub-container d-flex justify-content-center align-items-center">
        <div class="text-center">
          <img src="/img/trends-logo-loading.svg" alt="logo">
          <p class="title">{{ $t('components.trendsLoading.title') }}</p>
          <p class="subtitle">{{ $t('components.trendsLoading.subtitle') }}</p>
          <button @click="goToTrends()" class="btn btn-primary mt-4 btn-trends">
            {{ $t('components.trendsLoading.cta') }}
          </button>
          <p class="subsubtitle mt-2 mb-0">{{ $t('components.trendsLoading.subsubtitle') }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TrendsLoading',
  computed: {
    getEmail() {
      return this.$store.getters.getEmail;
    },
    getToken() {
      return this.$store.getters.getToken;
    },
    getUser() {
      return this.$store.getters.currentUser || {};
    },
  },
  methods: {
    goToTrends() {
      this.$mixpanel.track('fremium_trends_trigger', {});

      let domain
      if (this.getUser.interest == 'dropshipping') {
        domain = 'shopify.com'
      } else {
        domain = 'hotmart.com'
      }

      // const url = `http://localhost:5173/#/analytics?q=${domain}&email=${this.getEmail}&token=${this.getToken}`;
      const url = `https://trends.adminer.pro/#/analytics?q=${domain}&email=${this.getEmail}&token=${this.getToken}`;
      window.location.href = url;
    }
  },
  mounted() {
    this.$store.dispatch('fetchUser').then((user) => {
      if (!user.id) {
        this.$router.push({ name: 'UserRegister' });
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.background {
  background-color: #0A0A0A;
}

.container {
  padding-top: none;
  padding-bottom: none;
  height: 100vh;
}

.sub-container {
  height: 70vh;
}

.title {
  color: #FFFFFF;
  font-size: 50px;
  font-weight: 800;
  margin-bottom: 0;
}

.subtitle {
  color: #B5B3B3;
  font-size: 20px;
  font-weight: 500;
}

.subsubtitle {
  color: #B5B3B3;
  font-size: 16px;
  font-weight: 500;
}

.btn-trends {
  width: fit-content;
  border-radius: 8px;
}
</style>